import React, {useEffect} from 'react';

const convertNewlinesToBreaks = (text) => {
    if (!text) return null;
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
}
  
  
  const renderRichText = (richTextArray) => {
    if (!Array.isArray(richTextArray)) return null;
  
    return richTextArray.map((textItem, index) => {
      const { text, annotations } = textItem;
      const { bold, italic, underline, strikethrough, code, color } = annotations;
      const content = text.content;
  
      let className = "";
      if (bold) className += "article__bold";
      if (italic) className += "article__italic";
      if (underline) className += "article__underline";
      if (strikethrough) className += "article__strikethrough";
      if (code) className += "article__code";
      if (color !== "default") className += `article__color-${color} `;
  
      if (text.link) {
        return (
          <a key={index} href={text.link.url} className={`${className.trim()} article__link`}>
           {convertNewlinesToBreaks(content)}
          </a>
        );
      } else {
        return (
          <span key={index} className={className.trim()}>
            {convertNewlinesToBreaks(content)}
          </span>
        );
      }
    });
  };

  const List = ({ type, items }) => {
    const Tag = type === 'bulleted_list_item' ? 'ul' : 'ol';
    return (
      <Tag>
        {items.map((item, index) => ( 
          <li key={index} className='article__bullit'>{renderRichText(item)}</li>
        ))}
      </Tag>
    );
  };

  
  const Table = ({ table }) => (
    <table className="article__table">
      <tbody>
        {table.tableContent && table.tableContent.map((row, rowIndex) => (
          <tr key={rowIndex} className="article__table-tr">
            {row.cells.map((cell, cellIndex) => (
              <td key={cellIndex} className="article__table-th">
                {renderRichText([cell])} {/* Pass the cell as an array */}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
  
  export const BlockRenderer = ({ block }) => {
    useEffect(() => {
        if (block.type === 'code' && block.codeContent) {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.innerHTML = block.codeContent;
          document.body.appendChild(script);
    
          // Clean up the script after it runs to avoid duplications
          return () => {
            document.body.removeChild(script);
          };
        }
      }, [block]);

    switch (block.type) {
      case 'paragraph':
        return <p>{renderRichText(block.paragraph.rich_text)}</p>;
      case 'heading_1':
        return <h1>{renderRichText(block.heading_1.rich_text)}</h1>;
      case 'heading_2':
        return <h2>{renderRichText(block.heading_2.rich_text)}</h2>;
      case 'heading_3':
        return <h3>{renderRichText(block.heading_3.rich_text)}</h3>;
      case 'image':
        return (
          <img
            src={block.image.file.url}
            alt="Notion Image"
            className="article__picture" // Apply the CSS class for size and margin control
          />
        );
      case 'bulleted_list_item':
      case 'numbered_list_item':
        // return <li>{renderRichText(block[block.type].rich_text)}</li>;
        return <List type={block.type} items={[block.listItem.rich_text]} />;
      case 'table':
        return <Table table={block} />;
      case 'code':
        return (
           <pre className="code-block">
                <code>{block.codeContent}</code>
                {/* <code dangerouslySetInnerHTML={{ __html: block.codeContent }}/> */}
           </pre>
            );
      case 'to_do':
        return (
            <div>
                <input type="checkbox" checked={block.toDo.checked} readOnly />
                <span>{renderRichText(block.to_do.rich_text)}</span>
            </div>
                );
      case 'equation':
            return <div className="article__equation">{block.equationContent}</div>;
      case 'quote':
            return <blockquote className="article__quote"><span className="article__quote-sign">&#8220;</span>{renderRichText(block.quote.rich_text)}<span className="article__quote-sign">&#8221;</span></blockquote>;
      case 'callout':
            return (
            <div className="article__callout">
                  <span className="article__callout-icon">{block.callout.icon.emoji}</span>
                  {renderRichText(block.callout.rich_text)}
            </div>
                );
      case 'column':
            return (
            <div className="column">
                {block.columnContent.map((columnBlock) => (
                    <BlockRenderer key={columnBlock.id} block={columnBlock} />
                 ))}
            </div>
            );
      case 'column_list':
                return (
                <div className="column-list">
                    {block.columnListContent && block.columnListContent.map((column, index) => (
                      <div key={index} className="column">
                        {Array.isArray(column) && column.map((columnBlock) => (
                          <BlockRenderer key={columnBlock.id} block={columnBlock} />
                        ))}
                      </div>
                    ))}
                  </div>
                );
        case 'divider':
              return <hr className="article__divider" />;
        // Add cases for other block types as needed
      default:
        return <div>Unsupported block type</div>;
    }
  };
  