const Full = ({ color, bccolor, selected, onClick }) => (
    <svg
      className="insights__view"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      onClick={onClick}
      style={{
        fill: bccolor,
        border: `2px solid #001F3D`,
        borderRadius: '5px',
        backgroundColor: selected ? '#001F3D' : 'white', // Set background color based on selection
        transition: 'background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease',
        boxShadow: `${selected ? '0 4px 8px rgba(0, 0, 0, 0.15)' : '0 1px 2px rgba(0, 0, 0, 0.05)'}`, // Stronger shadow if selected
        transform: `${selected ? 'scale(1.1)' : 'scale(1)'}`, // Slightly enlarge if selected
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="80" height="80" style={{ fill: 'none' }} />
      <rect x="12" y="11" width="25" height="25" style={{ fill: selected ? 'white' : '#001F3D' }} /> {/* Change color based on selection */}
      <rect x="12" y="41" width="25" height="25" style={{ fill: selected ? 'white' : '#001F3D' }} />
      <rect x="43" y="41" width="25" height="25" style={{ fill: selected ? 'white' : '#001F3D' }} />
      <rect x="43" y="11" width="25" height="25" style={{ fill: selected ? 'white' : '#001F3D' }} />
    </svg>
  );


export default Full