import {Link} from "react-router-dom";
import './Footer.css';
import linkedin from '../../images/linkedin.png'
import twitter from '../../images/twitter.png'

export default function Footer() {
    return(
        <>
        <footer className='footer'>
        <div className='footer__info'>
        {/* <Link className='footer__link footer__link_title' to="./offer">
            Notre Offre
        </Link> */}
            <ul className='footer__list'>
            <li className='footer__outline'>Metaux</li>
            <li className="footer__list-item">
            <Link className='footer__link footer__link_subtitle' to="./copper">
                Cuivre
            </Link>
            </li>
            <li className="footer__list-item">
            <Link className='footer__link footer__link_subtitle' to="./nickel">
                Nickel
            </Link>
            </li>
            <li className="footer__list-item">
            <Link className='footer__link footer__link_subtitle' to="./cobalt">
                Cobalt
            </Link>
            </li>
            </ul>
        <Link className='footer__link footer__link_title' to="./articles">
            <div className='footer__link'>Le Marché</div>
        </Link>
        <Link className='footer__link footer__link_title' to="./about-us">
            <div className='footer__link'>Qui sommes-nous?</div>
        </Link>
        <Link className='footer__link footer__link_title' to="./contact-us">
            <div className='footer__link'>Contactez-nous</div>
        </Link>  
        </div>
        <div className='footer__reg'>
            <div  className='footer__reg-container' >
            <p className="footer__reg-copyright">© WeSafe 2024</p>
            <Link className="footer__reg-link" to="./legal-notice">{'Mentions légales'}</Link>
            <Link className="footer__reg-link" to="./general-conditions-of-use">{`CGU`}</Link>
            </div>
            <div>
            <Link to={"https://www.linkedin.com/company/wesafe-store/"} target="_blank">
                <img className="footer__reg-logo" src={linkedin} alt="linkedin" />
            </Link>
            <Link to={"https://x.com/WeSafeStore"} target="_blank">
                <img className="footer__reg-logo footer__reg-logo_x" src={twitter} alt="x" />
            </Link>
            </div>
        </div>
        </footer>
        </>
    )
}