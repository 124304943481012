import {React, useState, useRef, useEffect} from 'react';
import * as d3 from 'd3'
import './TransitionGraph.css'
import { useResizeObserver } from '../../utils/constants/useResizeObserver'
import { tooltipPositionTransition } from '../../utils/constants/tooltipPosition';
import CommonButtons from '../common/buttons/Buttons'


const data = [
    {
      "name":"copper",
      "values":[
        {"date": 2022,"Demande":25828.6, "Production":21900},
        {"date": 2025,"Demande":27872.96, "Production":22705.9},
        {"date": 2030,"Demande":35613.05, "Production":24521},
        {"date": 2035,"Demande":38231.58, "Production":26337.78},
        {"date": 2040,"Demande":40895.77, "Production":28154.56},
        {"date": 2045,"Demande":40360.74, "Production":29971.33},
        {"date": 2050,"Demande":38817.62, "Production":31788.11},
      ]
    },
    {
      "name": "nickel",
      "values": [
        {"date": 2022,"Demande":2933.685, "Production":3270},
        {"date": 2025,"Demande":4033.93, "Production":3464},
        {"date": 2030,"Demande":5815.25, "Production":3787.33},
        {"date": 2035,"Demande":6658.46, "Production":4110.67},
        {"date": 2040,"Demande":6701.84, "Production":4434},
        {"date": 2045,"Demande":6358.46, "Production":4757.33},
        {"date": 2050,"Demande":6196.07, "Production":5080.67},
      ]
    },
    {
      "name": "cobalt",
      "values": [
        {"date": 2022,"Demande":171.33, "Production":197},
        {"date": 2025,"Demande":280.8, "Production":199.13},
        {"date": 2030,"Demande":368.36, "Production":237.84},
        {"date": 2035,"Demande":425.78, "Production":276.56},
        {"date": 2040,"Demande":454.55, "Production":315.27},
        {"date": 2045,"Demande":498.24, "Production":353.99},
        {"date": 2050,"Demande":519.71, "Production":392.7},
      ]
    },
  ]

  var allGroup = ["Demande", "Production"]
  var myColor = d3.scaleOrdinal()
  .domain(allGroup)
  .range(["#AB3131", "#001F3D"]);  

export default function TransitionGraph() {
    const contRef = useRef();
    const [option, setOption] = useState("1");
    const [info, setInfo] = useState(null)
    
  const handleActiveOption = (value) => {
        setOption(value);
    }

    //UseEffect to control resize parameters for svg graphics
    const dimensions = useResizeObserver(contRef);

    const svgRef = useRef();

    useEffect(() =>{
        d3.select("svg").remove()
        d3.select("svg").remove()
        d3.select("svg").remove()

        if (!dimensions) return;

        var rowConverter = function(d) {
            return {
                date: new Date(+d.date, +0), //Make a new Date object for each year + month
                quantity: parseFloat(d.quantity)  //Convert from string to float
            };
        }

        var dataReady = allGroup.map(function(grpName) { // .map allows to do something for each element of the list
            return {
              name: grpName,
              values: data[option-1].values.map(function(d) {
                return {date: +d.date, quantity: +d[grpName]/1000};
              })
            };
          })

        // https://stackoverflow.com/questions/44017721/d3-js-v4-9-get-the-calculated-width-of-selected-element
        // https://d3-graph-gallery.com/graph/custom_responsive.html
        //https://www.youtube.com/watch?v=a4rstx9Pz2o&list=PLDZ4p-ENjbiPo4WH7KdHjh_EMI7Ic8b2B&index=8
        const w = dimensions.width
        const h = dimensions.height
        const padding = parseInt(d3.select('#transition-graph').style('font-size'), 10)
          
        const dataset = dataReady

        function scaleY () {
            if (option === "1"){
                return [0, 52]
            } else if (option === "2"){
                return  [0, 9]
            } else if (option === "3"){
              return  [0, 0.6]
            }
        }
        
        const xScale = d3.scaleLinear()
        .domain([            
            2022, 2050])
        .range([padding, w-30]);

        const yScale = d3.scaleLinear()
        .domain(scaleY())
        .range([h-padding, h*0.02])
        
        //Define axes
        const xAxis = d3.axisBottom()
                .scale(xScale)
                // .ticks(5)
                .tickFormat(d => d.toFixed(0))
                .tickValues([2022, 2025, 2030, 2035, 2040, 2045, 2050])  // Explicitly set the ticks to include 2022 and other years
                .tickFormat(d => d.toFixed(0))  // Ensure integer format for years
                // .attr("transform", "rotate(90)")


        //Define Y axis
        const yAxis = d3.axisLeft()
                .scale(yScale)
                .ticks(0)

        //Define line generator
        const line = d3.line()
        .x(function(d) { return xScale(+d.date); })
        .y(function(d) { return yScale(+d.quantity); });
        
        const svg = d3.select(svgRef.current)
        .append("svg")
        .attr("viewBox", '0 0 ' + w + ' ' + h)
        .attr("preserveAspectRatio", 'none')

        const tooltip = d3.select(svgRef.current)
        .append("div")
        .style("position", "absolute")
        .style("opacity", 0)
        .attr("class", "transition-graph__tooltip")

        
    //Create axes
        const xAxisGroup = svg.append("g")
        .attr("class", "transition-graph__axis")
        .attr("transform", "translate(0," + (h - padding) + ")")
        .call(xAxis);

        
                // Check if the container width is less than or equal to 450px
    if (dimensions.width <= 450) {
                  // Apply rotation for screens where the width of the container is <= 450px
          xAxisGroup.selectAll("text")
          .attr("transform", "rotate(90)")  // Rotate the labels by -45 degrees
          .style("text-anchor", "end")  // Align text to the end (to look neat)
          .attr("x",40)  // Adjust the x position to avoid overlap
          .attr("y",-5);  // Adjust the y position so it is below the axis  // Adjust the y position so it is below the axis
      }

      svg.append("g")
          .attr("class", "transition-graph__axis")
          .attr("transform", "translate(" + padding + ",0)")
          .call(yAxis)


    var yAxisGrid = yAxis
    .tickSize(-w + padding + h * 0.085, 0)
    .ticks(5)
    .tickPadding(10)
    .tickFormat(d => option === "3" ? d.toFixed(1) : d.toFixed(0));

    const gridGroup = svg.append("g")
        .classed('transition-graph__grid', true)
        .attr("transform", "translate(" + padding + ",0)")
        .call(yAxisGrid)
        .style("stroke-dasharray", ("3, 3"))
        .call(g => g.select(".domain").remove());  // Remove the Y-axis line

    // Remove only the first horizontal grid line, without affecting the tick label
    gridGroup.selectAll(".tick line")  // Select all grid lines
        .filter((d, i) => i === 0)     // Filter the first grid line (associated with the first tick)
        .remove();


    // const color =["#FF6B6B", "#001F3D"]
    const color =["#001F3D", "#AB3131"]
    const tooltipGraph = document.querySelector('.transition-graph__tooltip')

    // Add Tooltip Function with Debugging
    function addTooltip(selection, mainCircles, dataPoint) {
      selection
        .on("mouseover", function(event, d) {
          // Apply hover effect to the main circle that corresponds to the overlay
          const mainCircle = mainCircles.filter(mainD => mainD.date === d.date && mainD.quantity === d.quantity);
          if (dimensions.width >= 450) {
          mainCircle.transition()
            .duration(200)
            .attr("r", 10)  // Increase the radius of the main circle on hover
            .style("stroke-width", 12);  // Increase stroke width on hover
          }else{
            mainCircle.transition()
            .duration(200)
            .attr("r", 8)  // Increase the radius of the main circle on hover
            .style("stroke-width", 10);  // Increase stroke width on hover
          }
    
          tooltip
            .style("opacity", 0)
            .style("display", "none");
        })
        .on("mousemove", function(event, d) {
          tooltip
            .style("opacity", 1)
            .style("display", "block")
            .html(
              `${dataPoint.name} = ` +
              d.quantity.toFixed(1) +
              " M t<br><br> +" +
              ((d.quantity / dataPoint.values[0].quantity - 1) * 100).toFixed(0) +
              "% p/r à 2022"
            )
            .style("left", tooltipPositionTransition(event, tooltipGraph, w))
            .style("top", `${event.layerY + 15}px`)
            .transition();
        })
        .on("mouseleave", function(event, d) {
          // Restore the original style of the main circle
          const mainCircle = mainCircles.filter(mainD => mainD.date === d.date && mainD.quantity === d.quantity);
          mainCircle.transition()
            .duration(200)
            .attr("r", 5)  // Restore original radius
            .style("stroke-width", 7);  // Restore original stroke width
          tooltip
            .style("opacity", 0)
            .style("display", "none");
        });
      }

// Define the colors for the legend (same as used for the lines and area)
const legendData = [
  { name: 'Demande', color: "#001F3D" },      // Couleur de la demande
  { name: 'Déficit', color: "#4CAF50" },      // Couleur du déficit (Différence)
  { name: 'Production', color: "#AB3131" }    // Couleur de la Production
];

          

// Add legend to the SVG, positioned at the top-left corner
const legend = svg.append("g")
  .attr("class", "legend")
  .attr("transform", `translate(65, 5)`)
// Add a background rectangle to the legend
legend.append("rect")
  .attr("x", dimensions.Width <= 450 ? 0  : -5)  // Slight padding from the content
  .attr("y", dimensions.Width <= 450 ? 0  : -5)  // Slight padding from the content
  .attr("width", 120)  // Adjust the width according to the legend content
  .attr("height", legendData.length * 20 + 10)  // Adjust height based on the number of legend items
  .attr("fill", "#F5F5F5")  // Background color
  .attr("stroke", dimensions.Width <= 450 ? "#001F3D" : "none")  // Optional: Add a border
  .attr("stroke", "#001F3D")
  .attr("stroke-width", 1)  // Optional: Define border width
  .attr("rx", 5)  // Optional: Rounded corners for the background
  .attr("ry", 5);  // Optional: Rounded corners for the background

// Append rectangles (color boxes) and text for each legend item
legend.selectAll("rect.legend-box")
  .data(legendData)
  .enter()
  .append("rect")
  .attr("x", 0)
  .attr("y", (d, i) => i * 20)  // Position each legend box
  .attr("width", 18)
  .attr("height", 18)
  .attr("fill", d => d.color);  // Use the color corresponding to demand, deficit, and production

legend.selectAll("text")
  .data(legendData)
  .enter()
  .append("text")
  .attr("x", 25)  // Position text next to the color box
  .attr("y", (d, i) => i * 20 + 13)  // Align text with color boxes
  .text(d => d.name)  // Use the names 'Demand', 'Deficit (Difference)', and 'Production'
  .attr("font-size", "14px")
  .attr("font-weight", "bold")
  .attr("fill", "#001F3D");

  

    dataset.forEach(function(d) {
      // Create an area between production and demand for each material
      if (d.name === "Demande" || d.name === "Production") {
        const areaData = [];
        
        // Iterate through the data and calculate intersection points
        for (let i = 0; i < d.values.length - 1; i++) {
            const currentDemand = dataset[0].values[i].quantity;
            const currentProduction = dataset[1].values[i].quantity;
            const nextDemand = dataset[0].values[i + 1].quantity;
            const nextProduction = dataset[1].values[i + 1].quantity;
            const currentDate = d.values[i].date;
            const nextDate = d.values[i + 1].date;

            // Add the current point if demand is greater than production
            if (currentDemand > currentProduction) {
                areaData.push({
                    date: currentDate,
                    demand: currentDemand,
                    production: currentProduction
                });
            }

            // Check if the lines intersect between the current and next points
            if ((currentDemand > currentProduction && nextDemand < nextProduction) ||
                (currentDemand < currentProduction && nextDemand > nextProduction)) {
                
                // Calculate the intersection point
                const t = (currentProduction - currentDemand) / ((nextDemand - currentDemand) - (nextProduction - currentProduction));
                const intersectionDate = currentDate + t * (nextDate - currentDate);
                const intersectionValue = currentDemand + t * (nextDemand - currentDemand);

                // Add the intersection point to the area data
                areaData.push({
                    date: intersectionDate,
                    demand: intersectionValue,
                    production: intersectionValue
                });
            }
        }

        // Add the last point if demand is still greater than production
        const lastDemand = dataset[0].values[d.values.length - 1].quantity;
        const lastProduction = dataset[1].values[d.values.length - 1].quantity;
        if (lastDemand > lastProduction) {
            areaData.push({
                date: d.values[d.values.length - 1].date,
                demand: lastDemand,
                production: lastProduction
            });
        }

        // Create the area between production and demand where demand > production
        const area = d3.area()
            .x(d => xScale(d.date))   // X value is the date
            .y0(d => yScale(d.production))   // Bottom value is production
            .y1(d => yScale(d.demand));  // Top value is demand

        // Append the area
        svg.append("path")
            .datum(areaData)
            .attr("fill", "#4CAF50")
            .attr("fill-opacity", 0.4)
            .attr("class", "area")
            .attr("d", area);
    }

  });


    dataset.forEach(function(d, i) {
        // Proceed with the rest of your line and circle plotting as you already have it
        svg.datum(d.values)
            .append("path")
            .attr("class", "transition-graph__line")
            .attr("d", line)
            .attr("stroke", color[i]);

        const mainCircles = svg.append("g")
            .selectAll("dot")
            .data(d.values)
            .enter()
            .append("circle")
            .attr("cx", function(d) { return xScale(d.date); })
            .attr("cy", function(d) { return yScale(d.quantity); })
            .attr("r", 5)  // Initial radius of the main dot
            .attr("fill", color[i])  // Use the predefined color
            .attr("fill-opacity", 1)
            .style("stroke", color[i])
            .style("stroke-opacity", 0.4)
            .style("stroke-width", 7);

        svg.selectAll(".overlay-circle")
            .data(d.values)
            .enter()
            .append("circle")
            .attr("cx", function(d) { return xScale(d.date); })
            .attr("cy", function(d) { return yScale(d.quantity); })
            .attr("r", 20)  // Larger radius for easier touch interaction
            .attr("cursor", "pointer")
            .attr("fill", "transparent")
            .style("-webkit-tap-highlight-color", "transparent")  // Remove the tap highlight
            .attr("tabindex", "-1")  // Prevent focus, so no blue outline
            .style("outline", "none")  // Remove default outline
            .on("touchstart", function (event, d) {

              d3.select(this)
                .style("fill", "#F5F5F5")  // Apply the highlight color on touch
                .style("opacity", 0.6)     // Set opacity to 0.6
                .style("border-radius", "15px")  // Simulate a rounded effect
                .style("stroke", "#001F3D")  // Set the border color using stroke
                .style("stroke-width", "1px");  // Set the border width using stroke-width
            })
            .on("touchend", function (event, d) {
              d3.select(this)
                .style("fill", "transparent")  // Remove the touch highlight
                .style("opacity", 1)           // Reset opacity to 1
                .style("border-radius", null) // Reset border radius
                .style("stroke", "none")  // Remove the border after touch ends
                .style("stroke-width", "0");  // Reset stroke width
            })
            //.style("-webkit-tap-highlight-color", "transparent")
            .style("pointer-events", "all")  // Ensure the transparent overlay responds to events
            // .on("touchstart", (event, d) => showTooltip(event, d))  // Show tooltip on touch
            .call(addTooltip, mainCircles, d)

            dataset[0].values.forEach((demandPoint, index) => {
                  const productionPoint = dataset[1].values[index];
                  // Calculate the midpoint for the dot
                  const midY = (yScale(demandPoint.quantity) + yScale(productionPoint.quantity)) / 2;
                  const difference = (demandPoint.quantity - productionPoint.quantity).toFixed(1);
                  // Calculate the percentage difference relative to demand and production
                  const percentDiffDemand = ((difference / demandPoint.quantity) * 100).toFixed(0);
                  const percentDiffProduction = ((difference / productionPoint.quantity) * 100).toFixed(0);
                  if (difference > 0) {
                  // Adjust the position slightly so the arrows touch the dots
                  const offset = 5; // Adjust the offset to ensure the arrow touches the circle
            
            // Define the main green dot separately
            const mainDot = svg.append("circle")
              .attr("cx", xScale(demandPoint.date))
              .attr("cy", midY)
              .attr("r", 5)  // Initial radius of the green dot
              .attr("fill", "#4CAF50")
              .style("stroke", "white")
              .style("stroke-width", 7)
              .style("stroke-opacity", 0.2)
              .style("paint-order", "stroke") // Ensure stroke is rendered before other styles
      
              
      // Add a transparent overlay circle with a larger radius for easier interaction
      svg.selectAll(".overlay-circle")
              .data(d.values)
              .enter()
              .append("circle")
              .attr("cx", xScale(demandPoint.date))
              .attr("cy", midY)
              .attr("r", 20)  // Larger radius for easier touch interaction
              .attr("fill", "transparent")
              .style("pointer-events", "all")
              .style("cursor", "pointer")
              .style("-webkit-tap-highlight-color", "transparent")
              .attr("tabindex", "-1")
              .style("outline", "none")
                  .on("touchstart", function (event, d) {
                    d3.select(this)
                      .style("fill", "#F5F5F5")
                      .style("opacity", 0.6)
                      .style("stroke", "#001F3D")
                      .style("stroke-width", "1px");
                  })
                  .on("touchend", function () {
                    d3.select(this)
                      .style("fill", "transparent")
                      .style("opacity", 1)
                      .style("stroke", "none")
                      .style("stroke-width", "0");
                  })
                  .on("mouseover", function(event, d) {
                    // Increase the radius of the main dot on hover
                    if (dimensions.width >= 450) {
                    mainDot.transition()
                      .duration(50)
                      .attr("r", 10)  // Restore to original radius
                      .style("stroke-width", 12)
                  }else{
                    mainDot.transition()
                    .duration(50)
                    .attr("r", 8)  // Restore to original radius
                    .style("stroke-width", 10)
                  }})
                  .on("mousemove", function(event, d) {
                      tooltip
                      .style("opacity", 1)
                      .style("display", "block")
                      .html(`
                          Déficit = ${difference} M t<br><br>
                            ${percentDiffDemand}% de la demande
                        `)
                      .style("left", tooltipPositionTransition(event, tooltipGraph, w))
                      .style("top", `${event.layerY + 15}px`);
              
                      if (dimensions.width >= 450) {
                      mainDot.transition()
                      .duration(50)
                      .attr("r", 10)  // Restore to original radius
                      .style("stroke-width", 12)
                    }else{
                      mainDot.transition()
                      .duration(50)
                      .attr("r", 8)  // Restore to original radius
                      .style("stroke-width", 10)
                      }
                })
              .on("mouseleave", function() {
              tooltip
              .style("opacity", 0)
              .style("display", "none");
              mainDot.transition()
              .duration(50)
              .attr("r", 5)  // Restore to original radius
              .style("stroke-width", 7)
              });
          }
        });
      })        
    },[option, dimensions])

    const options = [
      { id: '1', value: '1', label: 'Cuivre' },
      { id: '2', value: '2', label: 'Nickel' },
      { id: '3', value: '3', label: 'Cobalt' }
    ];
  

    return (
    <section className='transition-graph'>
    <div className='transition-graph__title'> {'Projections de la demande, production et déficit mondiale\n(Millions de tonnes)'}</div>
        <div className='transition-graph__buttons'>
        <CommonButtons
        state={option}
        options={options}
        handleClick={handleActiveOption}
      />
      </div>
    { info !==null ? (
    <div className='transition-graph__background'>
    </div>) : null}
    <div id='transition-graph' className='transition-graph__graphics' ref={contRef}>
    <div className='transition-graph__graphics_inside' ref={svgRef}></div>
    </div>
    <div><a className='transition-graph__source' target='_blank' href='https://unctad.org/news/critical-minerals-boom-global-energy-shift-brings-opportunities-and-risks-developing-countries'>Source: CNUCED</a></div>
    </section>
    )
}