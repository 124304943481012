import {React, useState, useEffect} from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navigation.css";
import linkedin from '../../images/linkedin_blue.png';

function Navigation({handleActiveClick, isShowMenu, setIsShowMenu}) {
    const location = useLocation();
    const [openMetals, setOpenMetals] = useState(false);
    const [activeSubMenu, setActiveSubMenu] = useState(false);
    const [active, setActive] = useState("");

    useEffect(() => {
        if (location.pathname.includes("/copper") || location.pathname.includes("/nickel") || location.pathname.includes("/cobalt")) {
            setActive("2");
            setActiveSubMenu(true);
        } else if (location.pathname === "/articles") {
            setActive("3");
        } else if (location.pathname === "/wesafe-the-world") {
            setActive("4");
        } else if (location.pathname === "/about-us") {
            setActive("5");
        } else if (location.pathname === "/contact-us") {
            setActive("6");
        } else {
            setActive("");
            setActiveSubMenu(false);
        }
    }, [location]);

    const handleToggleMetals = (e) => {
        setOpenMetals(!openMetals);
        e.preventDefault();
    };

    const handleCloseBurgerMenu = () => {
        if (isShowMenu) {
            setIsShowMenu(false);
        }
    };

    const handleSubMenuClick = (e) => {
        handleActiveClick(e);
        setActive("2");
        setActiveSubMenu(true);
        setOpenMetals(false);  // Закрываем меню после клика
        handleCloseBurgerMenu();
    };

    const handleNavigationClick = (e) => {
        handleActiveClick(e);
        setActive(e.target.id);
        setActiveSubMenu(false);
        handleCloseBurgerMenu();
    };

    // Устанавливаем задержку перед закрытием меню после mouseleave
    const handleMouseLeaveWithDelay = () => {
        setTimeout(() => {
            setOpenMetals(false); // Закрываем меню через небольшую задержку
        }, 200); // 200ms задержки
    };

    return (
        <>
            <button 
                className={`navigation__link navigation__link_main navigation__link_button 
                ${!openMetals ? 'navigation__button-dropped' : 'navigation__button-undropped'} 
                ${activeSubMenu ? 'navigation__link_active' : 'navigation__link_inactive'}`} 
                onMouseEnter={handleToggleMetals} 
                onMouseLeave={handleMouseLeaveWithDelay} // Используем задержку перед закрытием меню
                onClick={handleToggleMetals} 
            >
                Nos Metaux&nbsp;&nbsp;&nbsp;
                <nav className={`navigation__dropdown ${openMetals ? 'open' : ''}`}>
                    <ul className="navigation__dropdown-list">
                        <li className="navigation__dropdown-item">
                            <Link id={"2"} 
                                className="navigation__link navigation__dropdown-link" 
                                to="/copper" 
                                onClick={(e) => { 
                                    handleSubMenuClick(e);
                                    setOpenMetals(false); // Закрываем меню после клика
                                }}
                            >
                                Cuivre
                            </Link>
                        </li>
                        <li className="navigation__dropdown-item">
                            <Link id={"2"} 
                                className="navigation__link navigation__dropdown-link" 
                                to="/nickel" 
                                onClick={(e) => { 
                                    handleSubMenuClick(e);
                                    setOpenMetals(false); // Закрываем меню после клика
                                }}
                            >
                                Nickel
                            </Link>
                        </li>
                        <li className="navigation__dropdown-item">
                            <Link id={"2"} 
                                className="navigation__link navigation__dropdown-link" 
                                to="/cobalt" 
                                onClick={(e) => { 
                                    handleSubMenuClick(e);
                                    setOpenMetals(false); // Закрываем меню после клика
                                }}
                            >
                                Cobalt
                            </Link>
                        </li>
                    </ul>
                </nav>
            </button>

            {/* Другие ссылки */}
            <Link 
                id={"3"} 
                className={`navigation__link navigation__link_main ${active === "3" ? "navigation__link_active" : null}`} 
                onClick={(e) => {handleNavigationClick(e);}} 
                to="/articles"
            >
                Le Marché
            </Link>

            <Link 
                id={"4"} 
                className={`navigation__link navigation__link_main ${active === "4" ? "navigation__link_active" : null}`} 
                onClick={(e) => {handleNavigationClick(e);}} 
                to="/wesafe-the-world"
            >
                WeSafe The World
            </Link>

            <Link 
                id={"5"} 
                className={`navigation__link navigation__link_main ${active === "5" ? "navigation__link_active" : null}`} 
                onClick={(e) => {handleNavigationClick(e);}} 
                to="/about-us"
            >
                Qui sommes-nous?
            </Link>

            <Link 
                id={"6"} 
                className={`navigation__link navigation__link_main ${active === "6" ? "navigation__link_active" : null}`} 
                onClick={(e) => {handleNavigationClick(e);}} 
                to="/contact-us"
            >
                Contactez-nous
            </Link>

            {/* Социальные сети и контактная информация */}
            <div className="navigation__network">
                <p className="navigation__phone">33 (0) 1 83 64 66 18</p>
                <Link className="navigation__linkedin-link" to={"https://www.linkedin.com/company/wesafe-store/"} target="_blank">
                    <img className="navigation__linkedin-logo" src={linkedin} alt="linkedin" />
                </Link>
            </div>
        </>
    );
}

export default Navigation;
