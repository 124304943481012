import "./CobaltProduct.css"
import Warehouse from '../Warehouse/Warehouse'
import MetalCard from '../MetalCard/MetalCard'
import copperA from '../../images/offer_copper.jpg'
import copperB from '../../images/offer_copper.jpg'
import cobalt from '../../images/aboutmetals/cobalt.jpg'
import {Helmet} from 'react-helmet-async'
import image from "../../images/logo_new.jpg"

export default function CobaltProduct() {

    const cards = [
        {title:'Cathodes de cuivre grade A', link: copperA, name: 'copper'},
        {title:'Cuivre Milberry', link: copperB, name: 'copper'},
        {title:'Bobine de cuivre', link: copperB, name: 'copper'},
    ]

    return(
        <>
        <Helmet>
            <title>Cobalt</title>
            <meta name="description" content="WeSafe vous propose d'acheter et d'épargner du Cobalt." />
            <link rel="canonical" href="https://wesafe.store/cobalt" />

            <meta property="og:url" content={`https://wesafe.store/cobalt`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Cobalt" />
            <meta property="og:description" content="L'achat de Cobalt." />
            <meta property="og:image" content={image} />

            <meta name="twitter:card" content="summary" />
            {/* <meta name="twitter:site" content="@yourusername" /> */}
            <meta name="twitter:title" content="Cobalt" />
            <meta name="twitter:description" content="L'achat de Cobalt." />
            <meta name="twitter:image" content={image} />
        </Helmet>
        <section className="cobalt">
        <div className="cobalt__topic">
                <h1 className="cobalt__title">{`L'achat de Cobalt sera disponible\nà partir de l'automne 2024`}</h1>
                <img className="cobalt__image" src={cobalt} alt="cobalt" />
                {/* <h2 className="cobalt__subtitle">COBALT</h2> */}
        </div>
        {/* <h3 className='cobalt__intro'>Nous vous proposons de garder un des nos produits dans nos entrepots</h3>
        <p className='cobalt__description'>Notre offre est lalalala Notre offre est lalalala Notre offre est lalalala Notre offre est lalalala 
        Notre offre est lalalala Notre offre est lalalala Notre offre est lalalala Notre offre est lalalala 
        Notre offre est lalalala Notre offre est lalalala Notre offre est lalalala Notre offre est lalalala
        Notre offre est lalalala Notre offre est lalalala Notre offre est lalalala Notre offre est lalalala</p>
        <div className="cobalt__container">
            {cards.map((card) => (
            <MetalCard key={card.title} card={card}/>
        ))}
        </div>
            <Warehouse/> */}
        </section>
        </>
    )
}