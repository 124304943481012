import { Link } from "react-router-dom";
import './AboutWeSafeTheWorld.css'
import arrow from '../../images/arrowupright.svg'

export default function AboutWeSafeTheWorld() {
    return(
<Link target="_blank" className="ecology__link" to="/wesafe-the-world">
<div className="ecology__container" >
<button className="ecology__button" >
<p className="ecology__text">{`Participez à notre programme d'amélioration de l'écosystème des matériaux critiques `}<img src={arrow} alt="arrow" /></p>
</button>
</div>
</Link>
    )
}