import {React, useRef, useEffect} from 'react';
import { useResizeObserver } from '../../../utils/constants/useResizeObserver';
import { tooltipPosition, tooltipPositionY } from '../../../utils/constants/tooltipPosition';
import * as d3 from 'd3'
import data from '../CopperDeficit/data/graphdata'
import greenDemandData from '../CopperDeficit/data/greendemand'
import consumption from '../CopperDeficit/data/consumptionbycountry'
import production from '../CopperDeficit/data/productionbycountry'
import deficitData from '../CopperDeficit/data/deficitdata'
import solar from '../../../images/articles/copperdeficit/solar_panel.svg'
import windoffshore from '../../../images/articles/copperdeficit/wind_offshore.svg'
import windonshore from '../../../images/articles/copperdeficit/wind_onshore.svg'
import electric_vehicle from '../../../images/articles/copperdeficit/electric_vehicle.svg'
import electric_station from '../../../images/articles/copperdeficit/electric_station.svg'
import { Link } from "react-router-dom";



export default function CopperDeficit () {
    const contPriceLineRef = useRef();
    const priceLineRef = useRef();
    const contDeficitRef = useRef();
    const deficitRef = useRef();
    const contGreenDemandRef = useRef();
    const greenDemandRef = useRef();
    const contConsumptionTreemap = useRef();
    const consumptionTreemap = useRef();
    const contProductionTreemap = useRef();
    const productionTreemap = useRef();
    const dimensionsPriceLine = useResizeObserver(contPriceLineRef);
    const dimensionsDeficit = useResizeObserver(contDeficitRef);
    const dimensionsGreenDemandLine = useResizeObserver(contGreenDemandRef)
    const dimensionsConsumptionTreemap = useResizeObserver(contConsumptionTreemap);
    const dimensionsProductionTreemap = useResizeObserver(contProductionTreemap);
    
    useEffect(() => {
      // d3.select(priceLineRef.current).select("svg").remove();
    
      if (!dimensionsPriceLine || !data || data.length === 0) return;
    
      const width = dimensionsPriceLine.width;
      const height = dimensionsPriceLine.height;
    
      // Parse the date / time
      const parseTime = d3.timeParse("%Y-%m");
    
      // Convert date strings to Date objects and ensure value is a number
      data.forEach(function(d) {
          d.date = parseTime(d.date); 
          d.value = +d.value;
      });
    
      // Handle the case where all dates might be invalid or undefined
      const validDates = data.filter(d => d.date !== null);
      if (validDates.length === 0) return;
    
      // Extend the x-domain slightly beyond the last date
      const maxDate = d3.max(validDates, d => d.date);
      if (!maxDate) return; // Early return if maxDate is undefined
    
      const extendedMaxDate = new Date(maxDate);
      extendedMaxDate.setMonth(maxDate.getMonth() + 1);
      const horizontalShift = width * 0.05;
    
      const svg = d3.select(priceLineRef.current)
          .append("svg")
          .attr("viewBox", '0 0 ' + width + ' ' + height)
          .attr("preserveAspectRatio", 'none')
          .append("g")
          .attr("transform", `translate(${width / 12 + horizontalShift},${height / 15})`);
    
      const x = d3.scaleTime()
          .domain([d3.min(validDates, d => d.date), extendedMaxDate])
          .range([0, width - 80]);
    
      const y = d3.scaleLinear()
          .domain([0, d3.max(validDates, function(d) { return +d.value; })])
          .range([height - 80, 0]);
    
      // Split the data into segments
      const beforeRange = validDates.filter(d => d.date < parseTime("2003-01"));
      const inRange = validDates.filter(d => d.date >= parseTime("2003-01") && d.date <= parseTime("2011-03"));
      const afterRange = validDates.filter(d => d.date > parseTime("2011-03"));
    
      // Add the line for the data before the range
      svg.append("path")
          .datum(beforeRange)
          .attr("fill", "none")
          .attr("stroke", "#001F3D")
          .attr("stroke-width", 1.5)
          .attr("d", d3.line()
              .x(d => x(d.date))
              .y(d => y(d.value))
          );
    
      // Add the line for the data in the range (red)
      svg.append("path")
          .datum(inRange)
          .attr("fill", "none")
          .attr("stroke", "red")
          .attr("stroke-width", 1.5)
          .attr("d", d3.line()
              .x(d => x(d.date))
              .y(d => y(d.value))
          );
    
      // Add the line for the data after the range
      svg.append("path")
          .datum(afterRange)
          .attr("fill", "none")
          .attr("stroke", "#001F3D")
          .attr("stroke-width", 1.5)
          .attr("d", d3.line()
              .x(d => x(d.date))
              .y(d => y(d.value))
          );
    
      // Create a tooltip div that is hidden by default
      const tooltip = d3.select("body").append("div")
          .style("position", "absolute")
          .style("background-color", "#001F3D")
          .style("color", "white")
          .style("border", "solid 1px black")
          .style("padding", "5px")
          .style("display", "none")
          .style("border-radius", "5px")
          .style("font-size", width < 600 ? "12px" : "14px") // Set font size based on width
          .attr("class", "tooltip");
    
      // Define the focusCircle element
      const focusCircle = svg.append("circle")
          .attr("r", 0)  // Initially hidden
          .attr("fill", "#001F3D")
          .attr("stroke", "white")
          .attr("stroke-width", 2)
          .style("pointer-events", "none");
    
      // Custom number formatting with spaces
      function formatNumberWithSpaces(number) {
          return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
    
      // Add circles to represent data points and color them based on the date range
      svg.selectAll("dot")
          .data(validDates)
          .enter()
          .append("circle")
          .attr("cx", d => x(d.date))
          .attr("cy", d => y(d.value))
          .attr("r", 1)
          .attr("fill", d => (d.date >= parseTime("2003-01") && d.date <= parseTime("2011-03")) ? "red" : "#001F3D")
          .style("cursor", "pointer")
          .on("mouseover", function(event, d) {
              tooltip.style("display", "block");
              tooltip.html(`Date: ${d3.timeFormat("%Y-%m")(d.date)}<br/>Value: ${formatNumberWithSpaces(d.value)}`)
                  .style("left", (event.pageX + 5) + "px")
                  .style("top", (event.pageY - 28) + "px");
          })
          .on("mouseout", function() {
              tooltip.style("display", "none");
          });
    
      // Add the Y axis with custom formatted ticks
      svg.append("g")
          .call(d3.axisLeft(y).tickFormat(d => formatNumberWithSpaces(d)))
          .style("cursor", "pointer");
    
      // Add the X axis
      svg.append("g")
          .attr("transform", "translate(0," + (height - 80) + ")")
          .call(d3.axisBottom(x)
              .ticks(d3.timeYear.every(2))
              .tickFormat(d3.timeFormat("%Y")))
          .selectAll("text")
          .attr("transform", "rotate(45)")
          .style("text-anchor", "end")
          .attr("dy", "0em")
          .attr("dx", "3.5em")
          .style("cursor", "pointer");
    
      // Add horizontal grid lines with dashed style, excluding the first tick
      const gridTicks = y.ticks().slice(1); // Remove the first tick
      svg.append("g")
          .attr("class", "grid")
          .selectAll("line.horizontal")
          .data(gridTicks)
          .enter()
          .append("line")
          .attr("class", "horizontal")
          .attr("x1", 0)
          .attr("x2", width - 80)
          .attr("y1", d => y(d))
          .attr("y2", d => y(d))
          .attr("stroke", "lightgray")
          .attr("stroke-width", 0.8)
          .attr("stroke-dasharray", "4,4");
    
      // Function to find the closest data point based on mouseX
      function getClosestDataPoint(mouseX) {
          const dateFromX = x.invert(mouseX);
          let closest = validDates[0];
          validDates.forEach(d => {
              if (Math.abs(d.date - dateFromX) < Math.abs(closest.date - dateFromX)) {
                  closest = d;
              }
          });
          return closest;
      }
    
      // Add an invisible rectangle to capture mouse events over the entire chart area
      svg.append("rect")
          .attr("width", width - 80)
          .attr("height", height - 80)
          .style("fill", "none")
          .style("pointer-events", "all")
          .style("cursor", "pointer")
          .on("mouseover", function() {
              tooltip.style("display", "block");
              focusCircle.attr("r", 5);
          })
          .on("mousemove", function(event) {
              const [mouseX] = d3.pointer(event, this);
              const closestPoint = getClosestDataPoint(mouseX);
              const closestX = x(closestPoint.date);
              const closestY = y(closestPoint.value);
    
              // Check if the closest point falls within the red range
              const isRed = closestPoint.date >= parseTime("2003-01") && closestPoint.date <= parseTime("2011-03");
    
              focusCircle
                  .attr("cx", closestX)
                  .attr("cy", closestY)
                  .attr("r", 7)
                  .attr("fill", isRed ? "red" : "#001F3D");
    
              // Position the tooltip to prevent it from going off the screen
              const tooltipWidth = 120;
              const tooltipX = event.pageX + 5;
              const tooltipY = event.pageY - 28;
    
              if (tooltipX + tooltipWidth > window.innerWidth) {
                  tooltip.style("left", (event.pageX - tooltipWidth - 5) + "px");
              } else {
                  tooltip.style("left", tooltipX + "px");
              }
    
              tooltip
                  .html("Date: " + d3.timeFormat("%Y-%m")(closestPoint.date) + "<br/>Prix: " + formatNumberWithSpaces(closestPoint.value) + " $/tonne")
                  .style("top", tooltipY + "px");
          })
          .on("mouseout", function() {
              tooltip.style("display", "none");
              focusCircle.attr("r", 0);
          });
    
    }, [dimensionsPriceLine]);
     
    useEffect(() => {
      // Clear the previous SVG
      d3.select(deficitRef.current).select("svg").remove();
  
      // Ensure the ref and dimensions exist
      if (!deficitRef.current || !dimensionsDeficit) {
          console.warn("SVG container or dimensions are missing.");
          return;
      }
  
      // Check for valid data
      if (!deficitData || deficitData.length === 0) {
          console.warn("No data available.");
          return;
      }
  
      const width = dimensionsDeficit.width;
      const height = dimensionsDeficit.height;
      const dotRadius = 7; // Radius of the dots
  
      const margin = { top: 20, right: 30, bottom: 50, left: 10 };
      const innerWidth = width - margin.left - margin.right;
      const innerHeight = height - margin.top - margin.bottom;
  
      // console.log("Inner dimensions:", innerWidth, innerHeight);
  
      // Create the SVG container
      const svg = d3.select(deficitRef.current)
          .append("svg")
          .attr("width", width)
          .attr("height", height)
          .append("g")
          .attr("transform", `translate(${margin.left},${margin.top})`);
  
      // Use a linear scale for the x-axis since dates are just years
      const x = d3.scaleLinear()
          .domain([2022, 2050])
          .range([20, innerWidth]);
  
      const y = d3.scaleLinear()
          .domain([20, d3.max(deficitData, group => d3.max(group.values, d => d.value))])
          .range([innerHeight, 0]);
  
      const xAxis = d3.axisBottom(x)
          .tickValues([2022, 2025, 2030, 2035, 2040, 2045, 2050])
          .tickFormat(d3.format("d")); // Ensure the year is formatted as an integer
  
      svg.append("g")
          .attr("transform", `translate(0, ${innerHeight})`)
          .call(xAxis)
          .selectAll("text")
          .style("font-family", "Gillroy")
          .style("font-size", "14px")
          .style("font-weight", "bold");
  
      const myColor = d3.scaleOrdinal()
          .domain(deficitData.map(group => group.name))
          .range(["#001F3D", "#646060"]);
  
      // Draw the lines
      const line = d3.line()
          .x(d => x(d.date))
          .y(d => y(d.value));
  
      svg.selectAll("myLines")
          .data(deficitData)
          .enter()
          .append("path")
          .attr("d", d => {
              const path = line(d.values);
              // console.log("Path data:", path);
              return path;
          })
          .attr("stroke", d => myColor(d.name))
          .style("stroke-width", 4)
          .style("fill", "none");
  
      // Add circles for each data point with individual tooltips
      deficitData.forEach((group, groupIndex) => {
          svg.selectAll(`circle-group-${groupIndex}`)
              .data(group.values)
              .enter()
              .append("circle")
              .attr("cx", d => x(d.date))
              .attr("cy", d => y(d.value))
              .attr("r", dotRadius)
              .attr("fill", myColor(group.name))
              .attr("stroke", "white")
              .attr("cursor", "pointer");
  
          // Add value labels for each dot
          svg.selectAll(`text-group-${groupIndex}`)
              .data(group.values)
              .enter()
              .append("text")
              .attr("x", d => x(d.date))
              .attr("y", d => y(d.value) + (groupIndex === 0 ? -10 : 20)) // Adjust position based on group index
              .attr("text-anchor", "middle")
              .style("font-family", "Gillroy")
              .style("font-size", "16px")
              .style("font-weight", "bold") // Make dot values bold
              .style("fill", myColor(group.name))
              .text(d => `${Math.round(d.value).toLocaleString('en-US').replace(/,/g, ' ')}`);
      });
  
      // Define an arrow marker for both ends of the line with the new color #001F3D
      svg.append("defs").append("marker")
          .attr("id", "arrow-end")
          .attr("viewBox", "0 0 10 10")
          .attr("refX", "5")
          .attr("refY", "5")
          .attr("markerWidth", "6")
          .attr("markerHeight", "6")
          .attr("orient", "auto")
          .append("path")
          .attr("d", "M 0 0 L 10 5 L 0 10 z")
          .attr("fill", "#001F3D");
  
      svg.append("defs").append("marker")
          .attr("id", "arrow-start")
          .attr("viewBox", "0 0 10 10")
          .attr("refX", "5")
          .attr("refY", "5")
          .attr("markerWidth", "6")
          .attr("markerHeight", "6")
          .attr("orient", "auto-start-reverse")
          .append("path")
          .attr("d", "M 0 0 L 10 5 L 0 10 z")
          .attr("fill", "#001F3D");
  
      // Add two-sided arrows and difference labels between corresponding points
      deficitData[0].values.forEach((d, i) => {
          const diff = Math.abs(d.value - deficitData[1].values[i].value);
  
          const y1 = y(d.value) + 5;
          const y2 = y(deficitData[1].values[i].value) - 2;
          const lineLength = Math.abs(y2 - y1);
          const adjustedLineLength = lineLength - dotRadius;
  
          const direction = y2 > y1 ? 1 : -1; // Determine direction of the line
  
          // Draw two-sided arrow line with #001F3D color
          svg.append("line")
              .attr("x1", x(d.date))
              .attr("x2", x(d.date))
              .attr("y1", y1 + direction * (dotRadius / 2))
              .attr("y2", y1 + direction * adjustedLineLength)
              .attr("stroke", "#001F3D")
              .attr("stroke-width", 1)
              .attr("marker-start", "url(#arrow-start)")
              .attr("marker-end", "url(#arrow-end)");
  
          // Create a group to contain the text and the background rectangle
          const textGroup = svg.append("g")
              .attr("transform", `translate(${x(d.date)}, ${(y(d.value) + y(deficitData[1].values[i].value)) / 2})`);
  
          // Add a white background rectangle with a border
          textGroup.append("rect")
              .attr("x", width < 600 ? -23 : -30) // Adjust position to fit text
              .attr("y", -10) // Adjust position to fit text
              .attr("width", width < 600 ? 45 : 60) // Width based on text length
              .attr("height", 20) // Height based on text size
              .attr("fill", "white")
              .attr("stroke", "#001F3D")
              .attr("stroke-width", "1px");
  
          // Place the difference value in the center of the line with bold text and "M t"
          textGroup.append("text")
              .attr("dy", "0.35em") // Vertically center the text
              .attr("text-anchor", "middle")
              .style("font-family", "Gillroy")
              .style("font-size", width < 600 ? "12px" : "14px")
              .style("font-weight", "bold") // Bold text
              .style("fill", "#001F3D")
              .text(`${Math.round(diff).toLocaleString('en-US').replace(/,/g, ' ')} M t`);
      });
  
      // Add legends (excluding the difference legend)
      const legend = svg.selectAll(".legend")
          .data(deficitData)
          .enter()
          .append("g")
          .attr("class", "legend")
          .attr("transform", (d, i) => `translate(0,${i * 20})`)
          .style("font-size", width < 600 ? "12px" : "16px");
  
      legend.append("rect")
          .attr("x", width < 600 ? -10 : 10)
          .attr("y", width < 600 ? -14 : 10)
          .attr("width", width < 600 ? 14 : 18)
          .attr("height", width < 600 ? 14 : 18)
          .style("fill", d => myColor(d.name));
  
      legend.append("text")
          .attr("x", width < 600 ? 8 : 34)
          .attr("y", width < 600 ? -8 : 19)
          .attr("dy", ".35em")
          .style("text-anchor", "start")
          .style("font-family", "inherit")
          .style("font-weight", "bold")
          .style("fill", d => myColor(d.name))
          .text(d => d.name);
  
      // Create tooltip for the dots
      const dotTooltip = d3.select("body").append("div")
          .attr("class", "dot-tooltip")
          .style("opacity", 0)
          .style("position", "absolute")
          .style("background-color", "white")
          .style("border", "solid 1px black")
          .style("padding", "5px")
          .style("pointer-events", "none");
  
  }, [dimensionsDeficit]);
  

  useEffect(() => {
    d3.select(greenDemandRef.current).select("svg").remove();
    if (!dimensionsGreenDemandLine) return;
  
    const width = dimensionsGreenDemandLine.width;
    const height = dimensionsGreenDemandLine.height;
  
    const margin = { top: 20, right: 20, bottom: 50, left: 20 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
  
    const svg = d3.select(greenDemandRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);
  
    // X-axis: scale and draw
    const x = d3.scaleLinear()
      .domain(d3.extent(greenDemandData[0].values, d => d.date))
      .range([0, innerWidth]);
  
    const xAxis = d3.axisBottom(x)
      .tickValues([2021, 2023, 2030, 2040])
      .tickFormat(d3.format("d")); // Format ticks as integers
  
    svg.append("g")
      .attr("transform", `translate(0, ${innerHeight})`)
      .call(xAxis)
      .selectAll("text")
      .style("font-family", "Gillroy")
      .style("font-size", width < 600 ? "14px" : "16px"); // Set font size based on screen width
  
    // Y-axis: scale and draw with percentage values, but it will be hidden
    const y = d3.scaleLinear()
      .domain([0, 100]) // Percentage range from 0% to 100%
      .range([innerHeight, 0]);
  
    // We are not appending the Y-axis to hide it.
  
    // Color scale: one color for each group
    const myColor = d3.scaleOrdinal()
      .domain(greenDemandData.map(group => group.name))
      .range(["#001F3D", "#B1ADAD"]);
  
    // Convert absolute values to percentages
    const percentageData = greenDemandData.map(group => {
      return {
        name: group.name,
        values: group.values.map(d => {
          const totalForYear = greenDemandData
            .find(g => g.name === "Demande totale")
            .values.find(item => item.date === d.date).value;
          return { 
            date: d.date,
            value: (d.value / totalForYear) * 100, // Calculate percentage
          };
        }),
      };
    });
  
    // Create the area generator for percentage data
    const area = d3.area()
      .x(d => x(d.date))
      .y0(innerHeight) // Baseline at the bottom of the chart
      .y1(d => y(d.value)); // Top of the area corresponds to the percentage value
  
    // Draw the areas for percentage data
    svg.selectAll("myAreas")
      .data(percentageData)
      .enter()
      .append("path")
      .attr("d", d => area(d.values))
      .attr("fill", d => myColor(d.name))
      .attr("opacity", 0.4); // Adjust opacity to make overlapping areas visible
  
    // Draw the lines for percentage data
    const line = d3.line()
      .x(d => x(d.date))
      .y(d => y(d.value));
  
    svg.selectAll("myLines")
      .data(percentageData)
      .enter()
      .append("path")
      .attr("d", d => line(d.values))
      .attr("stroke", d => myColor(d.name))
      .style("stroke-width", 4)
      .style("fill", "none");
  
    // Draw the points for percentage data
    const points = svg.selectAll("myDots")
      .data(percentageData)
      .enter()
      .append('g')
      .style("fill", d => myColor(d.name))
      .selectAll("myPoints")
      .data(d => d.values)
      .enter();
  
    points.append("circle")
      .attr("cx", d => x(d.date))
      .attr("cy", d => y(d.value))
      .attr("r", 7)
      .attr("stroke", "white")
      .attr("cursor", "pointer");
  
    // Add the value labels for "Demande des technologies propres"
    const techDemandData = percentageData.find(d => d.name === "Demande des technologies propres");
    if (techDemandData) {
      svg.selectAll("techDemandLabels")
        .data(techDemandData.values)
        .enter()
        .append("text")
        .attr("x", d => x(d.date))
        .attr("y", d => y(d.value) - 10) // Position the label slightly above the point
        .attr("text-anchor", "middle")
        .style("font-family", "Gillroy")
        .style("font-size", width < 600 ? "14px" : "16px")
        .style("font-weight", "bold") // Bold text
        .style("fill", myColor("Demande des technologies propres"))
        .text(d => `${d.value.toFixed(0)}%`); // Display percentage value
    }
  }, [dimensionsGreenDemandLine]);
  


useEffect(() => {
d3.select(consumptionTreemap.current).select("svg").remove();
  const treemapBottomY = document.querySelector(".front-article__treemap-container").offsetTop;
  if (!dimensionsConsumptionTreemap) return;

  const width = dimensionsConsumptionTreemap.width;
  const height = dimensionsConsumptionTreemap.height;
  const paddingTop = 15;

  // Create the root of the hierarchy using the simplified data
  const root = d3.hierarchy(consumption).sum(function(d){ return d.value });

  const svg = d3.select(consumptionTreemap.current)
    .append("svg")
    .attr("viewBox", '0 0 ' + width + ' ' + height)
    .attr("preserveAspectRatio", 'none');

  // Compute the treemap layout
  d3.treemap()
    .size([width, height])
    .paddingTop(paddingTop)
    .paddingRight(8)
    .paddingInner(0)  // Padding between each rectangle
    (root);

  // Prepare a color scale with custom colors
  const color = d3.scaleOrdinal()
    .domain(root.leaves().map(d => d.data.name))  // Map based on unique names
    .range(["#001F3D", "#B1ADAD"]);  // Use custom colors

  const opacity = d3.scaleLinear()
    .domain([d3.min(root.leaves(), d => d.value), d3.max(root.leaves(), d => d.value)])
    .range([.5, 1]);

  // Calculate the total value for percentage calculation
  const totalValue = d3.sum(root.leaves(), d => d.value);

  function formatWithSpaces(value) {
    return value.toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  function wrapText(text, width, height) {
    text.each(function() {
      const textElement = d3.select(this);
      const words = textElement.text().split(/\s+/).reverse();
      let word;
      let line = [];
      let lineNumber = 0;
      const lineHeight = 1.1; // ems
      const x = textElement.attr("x");
      const y = textElement.attr("y");
      const dy = parseFloat(textElement.attr("dy")) || 0;
      let tspan = textElement.text(null).append("tspan").attr("x", x).attr("y", y).attr("dy", dy + "em");

      const fontSize = Math.max(Math.min(width / 5, height / 2, Math.sqrt((width * width + height * height)) / 28), 8);
      textElement.attr("font-size", fontSize);

      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width && line.length > 1) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = textElement.append("tspan").attr("x", x).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
        }
      }
    });
  }

  // Draw rectangles
  svg
    .selectAll("rect")
    .data(root.leaves())
    .join("rect")
    .attr('x', function (d) { return d.x0; })
    .attr('y', function (d) { return d.y0; })
    .attr('width', function (d) { return d.x1 - d.x0; })
    .attr('height', function (d) { return d.y1 - d.y0; })
    .attr("class", "front-article__rect")
    .style("fill", function(d){ return color(d.data.name); })  // Use the color scale
    .style("opacity", function(d){ return opacity(d.data.value); })    

  // Add text labels for name, value, and percentage
  svg
    .selectAll('text')
    .data(root.leaves())
    .enter()
    .append('text')
    .attr("x", function(d){ return d.x0 + (d.x1 - d.x0) / 2; })
    .attr("y", function(d){ return d.y0 + (d.y1 - d.y0) / 3; })
    .attr("text-anchor", "middle")
    .attr("fill", "white")
    .each(function(d) {
      const text = d3.select(this);
      const percentage = Math.round((d.value / totalValue) * 100); // Calculate percentage of total, no decimals

      const rectWidth = d.x1 - d.x0;
      const rectHeight = d.y1 - d.y0;

      // Display the name
      text.text(d.data.name)
        .call(wrapText, rectWidth, rectHeight);

      // Display the formatted value with one decimal and percentage below the name
      text.append("tspan")
        .attr("x", function(d){ return d.x0 + (d.x1 - d.x0) / 2; })
        .attr("dy", "1.2em")
        .text(formatWithSpaces(d.value));

      text.append("tspan")
        .attr("x", function(d){ return d.x0 + (d.x1 - d.x0) / 2; })
        .attr("dy", "1.2em")
        .text("(" + percentage + "%)");
    });

}, [dimensionsConsumptionTreemap]);

useEffect(() => {
  d3.select(productionTreemap.current).select("svg").remove();
  const treemapBottomY = document.querySelector(".front-article__treemap-container").offsetTop;
  if (!dimensionsProductionTreemap) return;

  const width = dimensionsProductionTreemap.width;
  const height = dimensionsProductionTreemap.height;
  const paddingTop = 15;

  // Create the root of the hierarchy using the simplified data
  const root = d3.hierarchy(production).sum(function(d){ return d.value });

  const svg = d3.select(productionTreemap.current)
    .append("svg")
    .attr("viewBox", '0 0 ' + width + ' ' + height)
    .attr("preserveAspectRatio", 'none');

  // Compute the treemap layout
  d3.treemap()
    .size([width, height])
    .paddingTop(paddingTop)
    .paddingRight(8)
    .paddingInner(0)  // Padding between each rectangle
    (root);

  // Prepare a color scale with custom colors
  const color = d3.scaleOrdinal()
    .domain(root.leaves().map(d => d.data.name))  // Map based on unique names
    .range(["#001F3D", "#B1ADAD"]);  // Use custom colors

  const opacity = d3.scaleLinear()
    .domain([d3.min(root.leaves(), d => d.value), d3.max(root.leaves(), d => d.value)])
    .range([.5, 1]);

  // Calculate the total value for percentage calculation
  const totalValue = d3.sum(root.leaves(), d => d.value);

  function formatWithSpaces(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  function formatWithOneDecimal(value) {
    return parseFloat(value).toFixed(1);
  }

  function wrapText(text, width, height) {
    text.each(function() {
      const textElement = d3.select(this);
      const words = textElement.text().split(/\s+/).reverse();
      let word;
      let line = [];
      let lineNumber = 0;
      const lineHeight = 1.1; // ems
      const x = textElement.attr("x");
      const y = textElement.attr("y");
      const dy = parseFloat(textElement.attr("dy")) || 0;
      let tspan = textElement.text(null).append("tspan").attr("x", x).attr("y", y).attr("dy", dy + "em");

      const fontSize = Math.max(Math.min(width / 5, height / 2, Math.sqrt((width * width + height * height)) / 28), 9);
      textElement.attr("font-size", fontSize);

      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width && line.length > 1) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = textElement.append("tspan").attr("x", x).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
        }
      }
    });
  }

  // Draw rectangles
  svg
    .selectAll("rect")
    .data(root.leaves())
    .join("rect")
    .attr('x', function (d) { return d.x0; })
    .attr('y', function (d) { return d.y0; })
    .attr('width', function (d) { return d.x1 - d.x0; })
    .attr('height', function (d) { return d.y1 - d.y0; })
    .attr("class", "front-article__rect")
    .style("fill", function(d){ return color(d.data.name); })  // Use the color scale
    .style("opacity", function(d){ return opacity(d.data.value); });

  // Add text labels for name, value, and percentage
  svg
    .selectAll('text')
    .data(root.leaves())
    .enter()
    .append('text')
    .attr("x", function(d){ return d.x0 + (d.x1 - d.x0) / 2; })
    .attr("y", function(d){ return d.y0 + (d.y1 - d.y0) / 3; })
    .attr("text-anchor", "middle")
    .attr("fill", "white")
    .each(function(d) {
      const text = d3.select(this);
      const percentage = Math.round((d.value / totalValue) * 100); // Calculate percentage of total, no decimals

      const rectWidth = d.x1 - d.x0;
      const rectHeight = d.y1 - d.y0;

      // Display the name
      text.text(d.data.name)
        .call(wrapText, rectWidth, rectHeight);

      // Display the formatted value and percentage below the name
      text.append("tspan")
        .attr("x", function(d){ return d.x0 + (d.x1 - d.x0) / 2; })
        .attr("dy", "1.2em")
        .text(formatWithSpaces(formatWithOneDecimal(d.value)));

      text.append("tspan")
        .attr("x", function(d){ return d.x0 + (d.x1 - d.x0) / 2; })
        .attr("dy", "1.2em")
        .text("(" + percentage + "%)");
    });

}, [dimensionsProductionTreemap]);

    
    
    


    return (
        <>
        <h4  className='front-article__section-title'>Perspectives du Marché du Cuivre : Prix Actuels et Prévisions de Déficit</h4>
        <h5 className='front-article__section-subtitle'>Prix</h5>
        <p className='front-article__paragraph'>Un nouveau supercycle du cuivre est attendu, porté par la transition énergétique et la demande croissante pour 
          les technologies vertes. Ce scénario rappelle <span className='front-article__red'>le supercycle de 2003 à 2011, où la demande explosive, notamment de la Chine,
             et les contraintes d'approvisionnement</span> avaient déjà fait bondir les prix. 
            Comprendre ces dynamiques passées est essentiel pour anticiper les forces qui façonneront le marché dans les années à venir.</p>
        <p className='front-article__graph-title'>Cuivre - Qualité A - Prix au comptant LME (London Metal Exchange), $ / tonne</p>
        <p className='front-article__graph-title-hint'>{`[cliquez pour voir plus d'info]`}</p>
        <div className='front-article__price-line-container' ref={contPriceLineRef}>
        <div className='front-article__price-line' ref={priceLineRef}></div>
        </div>
        <p className='front-article__paragraph'>Les projections actuelles indiquent que la demande mondiale de cuivre pourrait largement dépasser 
          l'offre disponible, créant un écart significatif qui pourrait affecter les chaînes d'approvisionnement 
          et <span className='front-article__span-bold'>pousser les prix à des niveaux record</span>. De plus, les défis géopolitiques, 
          les préoccupations environnementales et les contraintes réglementaires <span className='front-article__span-bold'>pourraient compliquer encore davantage 
          l'extraction</span> et la production du cuivre.</p>

        <h5 className='front-article__section-subtitle'>Déficit</h5>
        <p className='front-article__graph-title'>Prédictions de la production minière mondiale, demande et déficit de cuivre, M de tonnes</p>
        <div className='front-article__price-line-container' ref={contDeficitRef}>
        <div className='front-article__price-line' ref={deficitRef}></div>
        </div>
        <p className='front-article__source'>
            Source: 
            <a target="_blank" href="https://unctad.org/news/critical-minerals-boom-global-energy-shift-brings-opportunities-and-risks-developing-countries" className='front-article__link'>UNCTAD</a>
            </p>
        <p className='front-article__paragraph'>La CNUCED (Conférence des Nations Unies sur le commerce et le développement), 
          se basant sur les données de l'AIE (Agence internationale de l'énergie) et de l'USGS (United States Geological Survey), 
          prévoit <span className='front-article__span-bold'>un déficit de 11 millions de tonnes d'ici 2030</span>.
          </p>
        <p className='front-article__paragraph'><a target="_blank" className='front-article__link' href='https://www.bhp.com/-/media/project/bhp1ip/bhp-com-en/documents/news/2024/240220_bhpeconomicandcommodityoutlook_february2024.pdf'>Dans son rapport de février 2024</a>, la plus grande entreprise minière, 
          BHP Billiton, estime un déficit similaire <span className='front-article__span-bold'>de 10 millions de tonnes</span> (7 Mt pour répondre à la croissance et 3 Mt pour compenser 
          la baisse projetée des opérations existantes).
      </p>
      <p className='front-article__paragraph'>En même temps, <a target="_blank" className='front-article__link' href='https://www.spglobal.com/commodityinsights/en/market-insights/latest-news/energy-transition/071422-world-copper-deficit-could-hit-record-demand-seen-doubling-by-2035-s-p-global'>
      S&P estime</a> que le déficit pourrait atteindre <span className='front-article__span-bold'>jusqu'à 9,9 millions de tonnes</span> par an d'ici 2035</p>
        <h4 className='front-article__section-title'>Le déficit va augmenter. Quels sont les raisons pour cette augmentation?</h4>
        <h4 className='front-article__section-subtitle'>Demande</h4>
        <h5 className='front-article__block-title'>1. Transition Ecologique</h5>
            <p className='front-article__paragraph'>
            La transition énergétique favorise l'adoption massive <span className='front-article__span-bold'>des énergies renouvelables et des véhicules électriques</span>, tous deux <span className='front-article__span-bold'>très dépendants du cuivre pour leurs infrastructures</span>. 
            Le développement des systèmes de stockage d'énergie, nécessaires pour stabiliser les réseaux, accroît également la demande de cuivre.
            </p>
            <p className='front-article__graph-title'>Combien de cuivre est nécessaire dans les technologies vertes ?</p>
            <div className='front-article__icons'>
              <div className='front-article__icon'>
                <p className='front-article__icon-title'>Systèmes d'énergie solaire</p>
                <img className='front-article__icon-image' alt="Solar panel" src={solar}></img>
                <p className='front-article__description'>4,56 t / MW</p>
              </div>
              <div className='front-article__icon'>
                <p className='front-article__icon-title'>Installations éoliennes offshore</p>
                <img className='front-article__icon-image' alt="Wind Offshore" src={windoffshore}></img>
                <p className='front-article__description'>13,5 t / MW</p>
              </div>
              <div className='front-article__icon'>
                <p className='front-article__icon-title'>Installations éoliennes onshore</p>
                <img className='front-article__icon-image' alt="Wind Onshore" src={windonshore}></img>
                <p className='front-article__description'>4 t / MW</p>
              </div>
              <div className='front-article__icon'>
                <p className='front-article__icon-title'>Véhicule<br></br>Électrique</p>
                <img className='front-article__icon-image' alt="Electric Vehicle" src={electric_vehicle}></img>
                <p className='front-article__description'>53,2 kg / véhicule</p>
              </div>
              <div className='front-article__icon front-article__icon_last'>
                <p className='front-article__icon-title'>Station<br></br>Électrique</p>
                <img className='front-article__icon-image' alt="Electric Vehicle" src={electric_station}></img>
                <p className='front-article__description'>
                  2 - 25 kg / borne de recharge<br></br>en fonction de type
                </p>
              </div>
            </div>
            <p className='front-article__source'>
            Source: 
            <a target="_blank" href="https://www.goldmansachs.com/insights/goldman-sachs-research/copper-is-the-new-oil" className='front-article__link'>Goldman Sachs</a>
            </p>
            <p className='front-article__paragraph'>Le cuivre est utilisé dans les panneaux photovoltaïques pour les connexions électriques et les câbles qui transportent l'électricité générée par les cellules solaires.</p>           
            <p className='front-article__paragraph'>Dans les éoliennes, le cuivre est principalement utilisé dans les générateurs, les transformateurs, et les câbles. Les éoliennes offshore utilisent plus de cuivre que celles onshore en raison de la nécessité de câbles sous-marins plus longs et plus robustes.</p>
            <p className='front-article__paragraph'>Les véhicules électriques nécessitent du cuivre pour les moteurs électriques, les batteries, le câblage, et les systèmes de charge.</p>
            <p className='front-article__paragraph'>Le cuivre est également crucial dans l'infrastructure de recharge pour les véhicules électriques, notamment dans les bornes de recharge et les câbles reliant les bornes au réseau électrique
            La quantité de cuivre varie en fonction du type de chargeur : une borne de recharge AC Niveau 1, souvent utilisée pour la recharge lente à domicile, 
            nécessite environ 2 kg de cuivre, tandis qu'une borne AC Niveau 2, plus rapide, en utilise environ 7 kg. 
            Les bornes de recharge rapide DC, déployées dans les stations publiques pour une recharge très rapide, peuvent nécessiter jusqu'à 25 kg de cuivre.</p>
            <p className='front-article__graph-title'>Prédictions de la demande des technologies propres dans la demande totale de cuivre (%)</p>
            <div className='front-article__price-line-container' ref={contGreenDemandRef}>
            <div className='front-article__price-line' ref={greenDemandRef}></div>
            </div>
            <p className='front-article__source'>
            Source: 
            <a target="_blank" href="https://www.iea.org/reports/copper" className='front-article__link'>IEA</a>
            </p>
            <h5 className='front-article__block-title'>2. Transition Numerique</h5>
            <div className='front-article__metals'>
            <div className='front-article__metals-title'>
            <p className='front-article__metals-title-number'>{`1 M\nde tonnes`}</p>
            <p className='front-article__metals-title-text'>pourraient être ajoutées à la demande mondiale de cuivre d'ici 2030 en raison de la croissance rapide des secteurs de l'intelligence artificielle (IA) et des centres de données</p>
            <p className='front-article__source'>Source: Reuters</p>
            </div>
            <div className='front-article__metals-text'>
            <p className='front-article__paragraph' >
            <a target="_blank" href="https://www.reuters.com/markets/commodities/ai-could-add-1-million-tons-copper-demand-by-2030-says-trafigura-2024-04-08/" className='front-article__link'>
            <br></br><br></br><br></br>Selon Reuters,</a> Trafigura estime que la demande liée à l'intelligence artificielle (IA) et aux centres de données pourrait avoir un impact majeur 
            sur le déficit de cuivre dans les années à venir. La croissance rapide de ces secteurs pourrait ajouter jusqu'à <span className='front-article__span-bold'>1 million de tonnes métriques </span>  
            à la demande mondiale de cuivre d'ici 2030.
            De plus, cette demande croissante  <span className='front-article__span-bold'>n'a pas encore été pleinement prise en compte dans de nombreuses prévisions actuelles</span>, 
            ce qui pourrait entraîner une sous-estimation de l'ampleur réelle du déficit.
            </p>
            </div>
            </div>
            <h5 className='front-article__block-title'>3. Infrastructure en Chine</h5>
            <p className='front-article__paragraph'>La demande en cuivre de la Chine d'ici 2030 sera fortement <span className='front-article__span-bold'>influencée par ses projets d'infrastructures</span>. 
            En tant que plus grand consommateur de cuivre au monde, la Chine utilise ce métal pour ses réseaux électriques, 
            la construction, et les technologies vertes. Par exemple, l'urbanisation continue et l'expansion des réseaux de transport 
            et d'énergie nécessiteront des millions de tonnes de cuivre.</p>
            <p className='front-article__graph-title'>Demande mondiale de produits semi-finis en cuivre en 2022 (M de tonnes)</p>
            <div className='front-article__treemap-container' ref={contConsumptionTreemap}>
            <div className='front-article__treemap-line' ref={consumptionTreemap}></div>
            </div>
            <p className='front-article__source'>
            Source:  
            <a target="_blank" href="https://www.coppercouncil.org/iwcc-statistics-and-data" className='front-article__link'>Copper Council</a>
            </p>
                <h4 className='front-article__section-title'>Offre</h4>
                <h5 className='front-article__block-title'>1. Rentabilité faible des investissements dans les mines</h5>
                <p className='front-article__paragraph'>
                Les entreprises de cuivre <span className='front-article__span-bold'>évitent de plus en plus les projets création de nouvelles mines</span> à partir de terrains vierges (greenfields) en raison de leur <span className='front-article__span-bold'>faible rentabilité</span>.
                Elles préfèrent se tourner vers les projets, qui sont considérés comme moins risqués et plus rentables :</p>
                <p className='front-article__bullit'><span className='front-article__span-italic'>1. Expansion ou réaménagement de mines existantes (brownfields)</span></p>
                <p className='front-article__paragraph'>Les projets brownfields, en particulier, nécessitent moins de capital initial et peuvent être développés plus rapidement grâce à l'infrastructure déjà existante.</p>
                <p className='front-article__bullit'><span className='front-article__span-italic'>2. Les fusions-acquisitions (M&A)</span></p>
                <p className='front-article__paragraph'>
                Par exemple, BHP, une entreprise minière australienne parmi les plus grandes au monde, avait envisagé une acquisition de 49 milliards de dollars
                pour Anglo American, une entreprise britannique, afin de renforcer sa position dans des secteurs clés comme le cuivre. 
                Néanmoins, l'accord n'a pas abouti en raison du refus d'Anglo American d'accorder plus de temps à BHP pour finaliser l'offre. 
                Après six semaines de négociations, BHP a abandonné le projet.
                </p> 
                <h5 className='front-article__block-subtitle'><span className='front-article__span-bold'>Pourquoi la rentabilité est faible? </span></h5> 
                <p className='front-article__paragraph'>
                Les projets greenfields sont plus complexes et coûteux, car ils exigent la construction de nouvelles infrastructures et sont soumis à des réglementations environnementales et sociales de plus en plus strictes. 
                Le développement de mine peut prendre entre <span className='front-article__span-bold'>4 et 12 années</span>, ajoutant ainsi aux défis financiers et logistiques de ces projets.
                </p>
                 <div className='front-article__exploration'>
                <div className='front-article__exploration-container'>
                  <p className='front-article__exploration-title'>1.Prospection et Exploration</p>
                  <p className='front-article__exploration-years'>Durée: 2-18 ans</p>
                  <p className='front-article__exploration-targets'>Recherche de gisements minéraux à l'aide de méthodes géologiques :</p>
                  <p className='front-article__exploration-comments'> <span className='front-article__span-bold'>Forage et analyse </span>pour estimer la taille potentielle et la valeur du gisement.</p>
                </div>
                <div className='front-article__exploration-container front-article__exploration-container_dev'>
                  <p className='front-article__exploration-title'>2.Développement</p>
                  <p className='front-article__exploration-years'>Durée: 4-12 ans</p>
                  <p className='front-article__exploration-targets'>
                    <ul className='front-article__list'>
                      <li className='front-article__bullit'>Planification</li>
                      <li className='front-article__bullit'>Obtention des permis</li>
                      <li className='front-article__bullit'>Construction d'infrastructures</li>
                      <li className='front-article__bullit'>Construction d'installations de traitement</li>
                    </ul>
                  </p>
                  <p className='front-article__exploration-comments'>Assure que <span className='front-article__span-bold'>la mine est prête</span> pour une exploitation continue</p>
                </div>
                <div className='front-article__exploration-container'>
                  <p className='front-article__exploration-title'>3.Extraction</p>
                  <p className='front-article__exploration-years'>Durée: 5-30 ans</p>
                  <p className='front-article__exploration-targets'>Extraction du cuivre</p>
                  <p className='front-article__exploration-comments'>La durée <span className='front-article__span-bold'>dépend de la taille et de la richesse du gisement</span></p>
                  <p className='front-article__exploration-comments'>Certaines mines fonctionnent pendant plus de 100 ans</p>
                </div>
                <div className='front-article__exploration-container'>
                  <p className='front-article__exploration-title'>4.Fermeture</p>
                  <p className='front-article__exploration-years'>Durée: varie</p>
                  <p className='front-article__exploration-targets'><span className='front-article__span-bold'>Restauration de la terre exploitée</span> à son état naturel ou préparation pour des utilisations alternatives</p>
                  <p className='front-article__exploration-comments'>La durée et le coût dépendent de la taille de la mine, de son emplacement et de son impact environnemental</p>
                </div>
              </div>
              <p className='front-article__paragraph'>De plus, la plupart des gisements 
                <span className='front-article__span-bold'>"high-grade" (à forte teneur) ont déjà été exploités</span>, ce qui rend les nouveaux projets greenfields moins attractifs en raison des 
                <span className='front-article__span-bold'> coûts d'extraction plus élevés</span> associés aux gisements de moindre qualité. 
                En outre, l'inflation <span className='front-article__span-bold'>augmente les coûts de construction des mines</span>, en particulier les salaires et le diesel pour les équipements miniers.</p>
              <p className='front-article__paragraph'><a target="_blank" className='front-article__link' href='https://www.mining.com/copper-humanitys-first-and-most-important-future-metal/#:~:text=Copper%20is%20the%20heartbeat%20of,copper%20as%20gasoline%2Dpowered%20cars.'>
              Mining.com estime que </a> l'intensité en capital, métrique principalement utilisée pour comparer les mines de cuivre en termes de coûts d'investissement (Investissement Total en Capital / Capacité de Production Annuelle), 
              a considérablement augmenté dans l'industrie minière.</p>
              <ul className='front-article__list'>
                <li className='front-article__bullit'>4 000 - 5 000 $US par tonne en 2000 </li>
                <li className='front-article__bullit'>10 000 $US par tonne en 2012</li>
                <li className='front-article__bullit'>Jusqu'à 44 000 $US par tonne en 2024</li>
              </ul>
              <p className='front-article__paragraph'><a target="_blank" className='front-article__link' href='https://www.bloomberg.com/news/articles/2024-04-24/blackrock-says-12-000-copper-is-needed-to-incentivize-new-mines'>
              Selon BlackRock World Mining Fund</a>, le prix du cuivre <span className='front-article__span-bold'>doit atteindre 12 000 dollars la tonne pour stimuler les investissements
                </span> dans de nouvelles mines, assurant un rendement de 15 % après impôt. 
                En dessous de ce seuil, les entreprises minières pourraient hésiter à investir.</p>
                <p className='front-article__paragraph'><a target="_blank" className='front-article__link' href='https://www.reuters.com/breakingviews/coppers-ma-mania-obscures-dysfunctional-market-2024-05-23/'>
                Selon les sources de Reuters</a>, les prix du cuivre doivent<span className='front-article__span-bold'> atteindre 11 020 dollars la tonne pour que les mineurs couvrent leurs coûts de capital</span> sur de nouveaux projets. 
                À 8 500 dollars la tonne en 2023, investir signifiait subir des pertes économiques.</p>
            <h5 className='front-article__block-title'>2. Risques politiques dans les pays producteurs de cuivre</h5>
            <p className='front-article__paragraph'>
            La production de cuivre est principalement concentrée dans les pays en développement, où <span className='front-article__span-bold'>le risque politique est souvent élevé</span>. 
            Ces régions, comme l'Amérique latine et certaines parties de l'Afrique (comme la République démocratique du Congo), 
            jouent un rôle clé dans l'approvisionnement mondial en cuivre. Cependant, l'instabilité politique, les conflits et les changements dans les politiques 
            gouvernementales peuvent perturber la production et l'approvisionnement.
            </p> 
            <p className='front-article__graph-title'>Production minière de cuivre en 2022 (M de tonnes)</p>
            <div className='front-article__treemap-container' ref={contProductionTreemap}>
            <div className='front-article__treemap-line' ref={productionTreemap}></div>
            </div>
            <p className='front-article__source'>
            Source: 
            <a target="_blank" href="https://www.bgs.ac.uk/news/world-mineral-production-2018-to-2022-is-now-available/" className='front-article__link'>British Geological Survey</a>
            </p>
            <p className='front-article__paragraph'>
            <a target="_blank" className='front-article__link' href='https://www.mining.com/web/panama-mine-shutdown-threatens-coppers-surplus/'>
            À titre d'exemple,</a> la mine Cobre Panama, exploitée par First Quantum Minerals, <span className='front-article__span-bold'>a été fermée récemment 
            en raison de conflits politiques et de manifestations au Panama</span>. Représentant environ <span className='front-article__span-bold'>1,5 % de l'approvisionnement mondial</span> en cuivre, cette fermeture depuis novembre 2023 a exacerbé la pénurie mondiale de cuivre
            et intensifié les préoccupations concernant l'approvisionnement futur.</p>
            <p className='front-article__paragraph'>
            En ce qui concerne la Russie, les sanctions <span className='front-article__span-bold'>internationales imposées à ce pays ont également des répercussions sur le marché</span> du cuivre. 
            La Russie, bien que moins dominante que d'autres pays, est un producteur significatif de cuivre. Les sanctions ont perturbé les chaînes d'approvisionnement,
            rendant plus difficile l'accès au cuivre russe sur les marchés mondiaux. Cela a contribué à accentuer les tensions sur l'offre globale de cuivre, 
            augmentant ainsi la pression sur les prix, dans un contexte où la demande pour ce métal reste forte, notamment en raison de la transition énergétique mondiale.
            </p>

        </>
    )
}