import {React, useState, useEffect} from 'react';
import {useParams} from 'react-router-dom'
import './Article.css';
import {insights} from '../../utils/utils'
import {Helmet} from 'react-helmet-async'
import {BlockRenderer} from '../../utils/notion/NotionRenderer'
import frontendInsights from "../../utils/utils"
import {URL} from '../../utils/constants/constants'

export default function Article() {
    const [article, setArticle] = useState(null); // Updated to handle null initially
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Added loading state
    const [progress, setProgress] = useState(0); // Track reading progress
    const { title, date } = useParams();
    const [isBackend, setIsBackend] = useState();

    const findLocalArticle = (title, date) => {
        return frontendInsights.find(article => {
            const articleTitle = article.name.replace(/[\s.]+/g, '-').toLowerCase();
            const articleDate = article.year.replace(/[\s.]+/g, '-').toLowerCase();
            return articleTitle === title && articleDate === date;
        });
    };

    useEffect(() => {
        // GET request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ date, title })
        };

        fetch(`${URL}/articles/${date}/${title}`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (data.message !== 'Article not found in backend') {
                    setIsBackend(1);
                    setArticle(data);
                } else {
                    const localArticle = findLocalArticle(title, date);
                    if (localArticle) {
                        setIsBackend(0);
                        setArticle(localArticle);
                    } else {
                        setError('Article not found');
                    }
                }
                setIsLoading(false); // Stop loading
            })
            .catch(error => {
                setError(error.message);
                setIsLoading(false); // Stop loading on error
            });
    }, [date, title]);

  useEffect(() => {
    const handleScroll = () => {

      let scrollTop;
      
      if (window.innerWidth > 800) {
        scrollTop = window.scrollY - 200; //insights__topic 
      }else if (550 < window.innerWidth < 800) {
        scrollTop = window.scrollY - 100;
      }else{
        scrollTop = window.scrollY - 350;  //insights__topic 
      }
        // 0.7 after 800px - 500
        let articleHeight;

        if (window.innerWidth > 450) {
        // Adjust for the width of footer
          articleHeight = document.documentElement.scrollHeight - window.innerHeight - 140 - 80; // footer__reg + footer__info
        } else {
          articleHeight = document.documentElement.scrollHeight - window.innerHeight - 200 - 500; // footer__reg + footer__info
        }

        const progress = (scrollTop / articleHeight) * 100;
        setProgress(progress);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
        window.removeEventListener("scroll", handleScroll);
    };
}, []);




    const url = `https://wesafe.store/articles/${date}/${title}`;

    const name = article?.name;
    const year = article?.year;
    const brief = article?.brief;
    const image = article?.image;
    const description = article?.description;

    const jsonLdData = {
        "@context": "https://schema.org",
        "@type": "Article",
        "headline": name,
        "description": brief,
        "image": image,
        "author": {
            "@type": "Organization",
            "name": "WeSafe"
        },
        "datePublished": date.split('.').reverse().join('-'), // Format as YYYY-MM-DD
        "url": url,
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": url
        }
    };

    const breadcrumbJsonLd = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://wesafe.store/"
            },
            {
                "@type": "ListItem",
                "position": 2,
                "name": "Le Marché",
                "item": "https://wesafe.store/articles"
            },
            {
                "@type": "ListItem",
                "position": 3,
                "name": name,
                "item": url
            }
        ]
    };

    return (
        <>
            <Helmet>
                <title>{name}</title>
                <meta name="description" content={brief} />
                <link rel="canonical" href={url} />

                <meta property="og:url" content={url} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={name} />
                <meta property="og:description" content={brief} />
                <meta property="og:image" content={image} />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content={name} />
                <meta name="twitter:description" content={brief} />
                <meta name="twitter:image" content={image} />
                <script type="application/ld+json">
                    {JSON.stringify(jsonLdData)}
                </script>
                <script type="application/ld+json">
                    {JSON.stringify(breadcrumbJsonLd)}
                </script>
            </Helmet>
            <div className="article__progress-bar" style={{ width: `${progress}%` }}></div> {/* Progress Bar */}
            <section className='article'>
                <div className="article__topic">
                    <h1 className="article__topic-title">Actualités & Analyses</h1>
                    <h2 className="article__topic-subtitle">Suivez les dernières mises à jour sur le marché avec nous !</h2>
                </div>

                {isLoading ? (
                    <div className="article__loading">Chargement en cours...</div>
                ) : error ? (
                    <div className="article__loading">Erreur: {error}</div>
                ) : (
                    <div className='article__container'>
                        <h3 className='article__title'>{name}</h3>
                        <p className='article__date'>{year}</p>
                        <div className='article__description'>
                            {isBackend === 1 ? (
                                description.map((block) => (
                                    <BlockRenderer key={block.id} block={block} />
                                ))
                            ) : (
                                description
                            )}
                        </div>
                    </div>
                )}
            </section>
        </>
    );
}
