import React from 'react';
import './Buttons.css';  // Убедитесь, что здесь подключены все стили

const Buttons = ({ state, options, handleClick }) => {
    //console.log('options:', options);  // Логирование для проверки структуры options
    return (
        <>
        {options.map((option, index) => (
          <button
            key={index}
            id={option.id} 
            className={`common__button ${state === option.value ? 'common__button_active' : 'common__button_inactive'}`}
            onClick={() => handleClick(option.value)}
          >
            {option.label}
          </button>
        ))}
        </>
    );
  };
  

export default Buttons;
