const greenDemandData = [
    {
      "name":"Demande des technologies propres",
      "values":[
        {"date": 2021,"value":5.380},
        {"date": 2023,"value":6.311},
        {"date": 2030,"value":12.001},
        {"date": 2040,"value":16.343}
      ]
    },
    {
      "name": "Demande totale",
      "values":[
        {"date": 2021,"value":24.928},
        {"date": 2023,"value":25.855},
        {"date": 2030,"value":31.128},
        {"date": 2040,"value":36.379}
      ]
    }]

export default greenDemandData;