import React, {useEffect, useState} from 'react';
import './AboutTeam.css';
import {team} from '../../utils/utils'

export default function AboutTeam() {
    

    return(
        <>
        <section className='team'>
            <h2 className='team__title'>Qui sommes-nous?</h2>
            <div className='team__container'>
            {team.map((person) => 
                <div className='team__card' key={person.name}>
                <img className='team__photo' src={person.src} alt={person.profession}/>
                <h3 className='team__name'>{person.name}</h3>
                <h4 className='team__profession'>{person.profession}</h4>
                <div className='team__description'>{person.description}</div>
                </div>
                )}
            </div>
        </section>
        </>
    )
}