export const dataPIA4 ={"children":[
    // {"name": "Programme N 425 - Financement structurel\ndes écosystèmes d’innovation",
    {"name": "№425",
    "children":[
    {"name":"Financements de l’écosystème ESRI et valorisation","group":"B","value":3160, 
    "responsible": " ANR (90%), CDC (8%), Reste à Affecter (2%)", 
    "objectives":
    `<b>Objectifs</b>:<br><br>
    • transformer les écoles, collèges, lycées et universités pour les tirer vers le meilleur niveau
    mondial<br>
    • financer des laboratoires, des équipements et des programmes de recherche de grande ampleur<br>
    • amplifier l’effort en faveur de la recherche biomédicale, du développement des connaissances en
    matière de santé et de l’amélioration des pratiques médicales, notamment à travers les instituts et
    les projets de recherche hospitalo-universitaires (IHU et RHU)<br>
    • soutenir les instituts de recherche technologique (IRT) et pour la transition énergétique (ITE),
    organismes partenariaux qui réunissent industriels et acteurs de la recherche publique<br>
    • accélérer la structuration du paysage des acteurs de la maturation et du transfert technologique<br><br>Source:PLF 2024`, 
    "colname":"level3"},
    {"name":"Aides à l’innovation «bottom-up» (subventions et prêts)","group":"A","value":4003, 
    "responsible": " BPI (95%), CDC(2%), Reste à Affecter(3%)", 
    "objectives":
    `<b>Objectifs</b>:<br><br>
    • aider aux entreprises innovantes qui, individuellement ou dans le cadre de programmes collaboratifs, ont besoin d’accéder à des sources de financement pour
    couvrir le risque inhérent à leurs projets de R&D et d’innovation.<br><br>Source:PLF 2024`, 
    "colname":"level3"},
    {"name":"Aides à l’innovation «bottom-up» (fonds propres)","group":"A","value":3000, 
    "responsible": " BPI (87%), Reste à Affecter(13%)", 
    "objectives":
    `<b>Objectifs</b>:<br><br>
    De soutenir la croissance de <b>start-ups à tous les stades de leur développement</b> et d’apporter un financement en fonds propres aux projets
    d’accélération des implantations de start-ups. Les instruments envisagés sont les suivants : <br><br>
    • <b>Fonds « multicap croissance n° 4 » (1,1 Md€)</b> à soutenir l’émergence de fonds de capital risque et de « growth » français<br>
    • <b>Fonds « Métaux critiques » (505 M€)</b> à investir dans des projets sur l’ensemble de la chaîne de valeur des minerais et métaux stratégiques,
    de l’extraction au recyclage, en vue de sécuriser les approvisionnements de l’industrie française et européenne.<br>
    • <b>Fonds « Scale-Up »</b>, géré par le Fonds européen d’investissement. France contribue 1 Md€.<br><br>Source:PLF 2024 
    `,"colname":"level3"},
    ],
    "colname":"level2"},
    
    // {"name":"Programme N 424 - Financement des investissements stratégiques",
    {"name": "№424",
    "children":[
    {"name":"Programmes et equipments prioritaires de recherche",
    "group":"B",
    "value":3000,
    "responsible": "BPI",
    "objectives":
    `<b>Objectifs</b>:<br><br> 
    • Sélectionner et financer, sur des champs de recherche circonscrits et dont l’impact socio-économique
    potentiel est documenté, des projets contribuant à construire ou consolider le leadership de la
    recherche française<br>
    • Identifier et sélectionner des chercheurs de rang international, à même de porter des projets de
    recherche de rupture pour une meilleure attractivité de la recherche<br>
    • Identifier et soutenir des équipements et infrastructures de recherche d’envergure nationale,
    fortement mutualisés, qui soient les plus nécessaires et les plus structurants pour les communautés
    scientifiques.)<br><br>Source:PLF 2024`
    
    ,
    "colname":"level3"},
    {"name":"Maturation de technologies, R&D, valorisation de la recherche","group":"A","value":3000, 
     "responsible": " Ademe(7%), ANR (24%), BPI (60%),<br> Autres(2%), Reste à Affecter (7%)", 
    "objectives":
    `<b>Objectifs</b>:<br><br> 
    • Soutenir des programmes d’innovation de rupture sur le modèle des « grands défis » financés par le Fonds
    pour l’innovation et l’industrie, qui constituent des formes d’actions nouvelles dans lesquelles la prise
    de risque est particulièrement favorisée. L’ambition est d’identifier les ruptures technologiques, qui
    répondent à des enjeux majeurs de transition et qui sont souvent susceptibles d’irriguer plusieurs
    marchés (technologies diffusantes)<br>
    • Soutenir l’accompagnement de projets de R&D portés par des entreprises, seules ou en consortium, en
    partenariat avec des laboratoires de recherche publique (validation de preuves de concept,
    développement de prototypes, levée de verrous technologiques)<br>
    • Soutenir un effort particulier de transfert technologique dans les domaines stratégiques prioritaires ciblés, à
    travers des programmes d’accompagnement portés par les organismes et structures de valorisation<br><br>Source:PLF 2024
    `, 
    "colname":"level3"},
    {"name":"Démonstration en conditions réelles, amorçage et premières commerciales","group":"A","value":7500, 
    "responsible": " Ademe(17%), ANR (2%), BPI (46%),<br> CDC(10%), Autres(10%), Reste à Affecter (15%)",
    "objectives":`
    <b>Objectifs</b>:<br><br> 
    • Soutenir la mise en oeuvre de premières réalisations industrielles ou de services à l’échelle pilote ou
    démonstrateur, visant à vérifier la viabilité organisationnelle et technico économique d’innovations, dont les
    principaux verrous technologiques ont été levés.<br><br> 
    • L'action peut accompagner des expérimentations en conditions réelles ou représentatives auprès des populations, dans des territoires, écosystèmes ou établissements, afin
    d’identifier des ruptures d’usage, d’enrichir et de valider les innovations ayant un fort potentiel de réplication
    ou de diffusion<br><br>Source:PLF 2024`, 
    "colname":"level3"},
    {"name":"Soutien au déploiement","group":"D","value":10465, 
     "responsible": " Ademe(15%), ANR (17%), BPI (41%),<br> CDC(10%), Autres(1%), Reste à Affecter (16%)", 
    "objectives":`
    <b>Objectifs</b>:<br><br> 
    • garantir l’effectivité des conditions de diffusion et d’adoption des innovations, permettant
    leur déploiement à grande échelle et l’atteinte des impacts socio-économiques attendus, ainsi qu’à renforcer
    la souveraineté et la résilience de nos modèles sur certaines chaînes de valeur stratégiques<br> 
    • soutenir l’implantation de sites industriels, en priorité par des PME et ETI, et des
    projets nécessitant un passage à l’échelle afin de sécuriser l’industrialisation des technologies et ainsi
    décupler les retombées sur le territoire national.<br>
    • accompagner les entreprises françaises au sein des chaînes de valeur stratégiques définies au niveau européen<br><br>Source:PLF 2024`,
    "colname":"level3"},
    {"name":"Accélération de la croissance","group":"D","value":3499, 
    "responsible": " BPI (44%), Reste à Affecter (55%)", 
    "objectives":`
    <b>Objectifs</b>:<br><br>
    Gerer les fonds d'investissement:<br>
    •  « Fonds écotechnologies 2 » (350 M€)<br>
    •  Fonds « French tech Souveraineté » (500 M€)<br>
    •  Fonds SPI-Société de projets industriels n° 2 (300 M€)<br>
    •  « Fonds national de venture industriel – FNVI » (350 M€)<br>
    •  Fonds « Deep tech » (100 M€)<br>
       et les autres<br><br>Source:PLF 2024
    `, 
    "colname":"level3"},
    {"name":"Industrialisation et déploiement","group":"D","value":12948,  
    "responsible": " Ademe(40%), BPI (53%), CDC(2%), Reste à Affecter (5%)", 
    "objectives":`
    <b>Objectifs</b>:<br><br>
    Soutenir l’industrialisation et le déploiement de projets stratégiques situés plus en « aval » dans le processus d’innovation, 
    que ceux financés par les actions existantes.<br><br>
    <b>En particulier</b>, soutenir:<br>
    • décarbonation de l’industrie<br>
    • développement de véhicules connectés zéro émission<br>
    • puces électroniques, les robots industriels<br>
    • contenus culturels et le renouvellement forestier<br><br>Source:PLF 2024`,
    "colname":"level3"}],
    "colname":"level2"},
    ],
    "name":"Programmes"}

    export const dataPIA3 ={"children":[
    {"name":"№423",
    "children":[
    {"name": "Soutien à l'innovation collaborative (PSPC)","group":"B", "value":600,"colname":"level3"},
    {"name": "Accompagnement et transfo des filières (Aides d'Etat)","group":"B", "value":791,"colname":"level3"},
    {"name": "Accompagnement et transfo des filières SPI","group":"B", "value":716,"colname":"level3"},
    // {"name":"Action 4 - Industrie du futur - FDG Prêt industrie du futur","group":"D","value":0,"colname":"level3"},
    // {"name":"Action 5 - Industrie du futur Développement de l'offre","group":"D","value":0,"colname":"level3"},
    {"name":"Adaptation et qualification de la main d'œuvre ingénierie de formation","group":"D","value":167,"colname":"level3"},
    {"name":"Adaptation et qualification de la main d'œuvre -French Tech tickets et diversité","group":"D","value":26,"colname":"level3"},
    {"name":"Concours d'innovation - Bpifrance","group":"D","value":293,"colname":"level3"},
    {"name":"Concours d'innovation - ADEME","group":"D","value":110,"colname":"level3"},
    {"name":"Fonds national d'amorçage n°2 (FNA2)","group":"D","value":500,"colname":"level3"},
    {"name":"Fonds à l'internationalisation des PME","group":"D","value":4,"colname":"level3"},
    {"name":"Multi cap croissance n° 2 (MC3)","group":"D","value":600,"colname":"level3"},
    {"name":"Grands défis","group":"D","value":650,"colname":"level3"},
],
    "colname":"level2"},
    
    // {"name":"Programme N 422 - Valorisation de la Recherche",
    {"name":"№422",
    "children":[
    {"name": "Intégration des SATT, incubateurs et accélérateurs (aides d'Etat)","group":"B", "value":50,"colname":"level3"},
    {"name": "Intégration des SATT, incubateurs et accélérateurs (fonds ET accélération)","group":"B", "value":100,"colname":"level3"},
    {"name": "Fonds national post maturation Frontier Venture","group":"B", "value":500,"colname":"level3"},
    {"name": "Démonstrateurs et territoire d'innovation de grande ambition Démonstrateurs (aides d'Etat)","group":"B", "value":250,"colname":"level3"},
    {"name": "Démonstrateurs et territoire d'innovation de grande ambition Démonstrateurs (fonds First of a kind)","group":"B", "value":400,"colname":"level3"},
    {"name": "Démonstrateurs et territoire d'innovation de grande ambition TIGA","group":"B", "value":511,"colname":"level3"},
    {"name": "Démonstrateurs et territoire d'innovation de grande ambition Nucléaire de demain","group":"B", "value":619,"colname":"level3"},
    {"name": "Nouveaux écosystèmes d'innovation IHU2","group":"B", "value":65,"colname":"level3"},
    {"name": "Nouveaux écosystèmes d'innovation Expérimentations FNV","group":"B", "value":30,"colname":"level3"},
    {"name": "Développement des écosystèmes d'innovation performants Accélération SATT","group":"B", "value":200,"colname":"level3"},
    {"name": "Développement des écosystèmes d'innovation performants RHU","group":"B", "value":147,"colname":"level3"},
    {"name": "Développement des écosystèmes d'innovation performants Technologies numériques (+ CCF 876 pour le prêt)","group":"B", "value":590,"colname":"level3"},
    {"name": "Développement des écosystèmes d'innovation performants - Nano","group":"B", "value":98,"colname":"level3"},
    {"name": "Accélération du développement des écosystèmes d'innovation performants Transports et mobilité durable","group":"B", "value":125,"colname":"level3"},
    ],"colname":"level2"},
    
    // {"name":"Programme N 421 - Soutien des progrès de l’enseignement et de la recherche",
    {"name":"№421",
    "children":
    [{"name":"Nouveaux cursus à l’université","group":"A","value":280,"colname":"level3"},
    {"name":"Programmes prioritaires de recherche","group":"A","value":310,"colname":"level3"},
    {"name":"Équipements structurants de recherche","group":"A","value":340,"colname":"level3"},
    {"name":"Soutien des grandes universités de recherche","group":"A","value":794,"colname":"level3"},
    {"name":"Constitution d’écoles universitaires de recherche","value":270,"colname":"level3"},
    {"name":"Sociétés universitaires et de recherche","group":"C","value":80,"colname":"level3"},
    {"name":"Territoires d’innovation pédagogique","group":"C","value":295,"colname":"level3"}
    ],"colname":"level2"},
    ]}