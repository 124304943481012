import { Link} from "react-router-dom";
import './AboutMetals.css';
import TransitionGraph from '../TransitionGraph/TransitionGraph'
import refsvg from '../../images/ref.svg'
import arrow from '../../images/arrowupright.svg'
import image from '../../images/offer_copper.jpg'


export default function AboutMetals() {
    return(
        <>
        <section className='metals'>
        <h2 className='metals__title'>Pourquoi les métaux critiques ?</h2>
        <div className='metals__info'>
        <div className='metals__container'>
            <div className='metals__description'>
            <p className='metals__paragraph'>
            Ces métaux constituent la base de la <span className="metals__paragraph_bold">transition numérique et énergétique</span>
            </p>
            <p className='metals__paragraph'>
            <span className="metals__paragraph_bold">Un déficit durable des métaux critiques</span> est attendu, ce qui posera des défis à long terme pour les industries technologiques et énergétiques.
            </p>
            <p className='metals__paragraph'>
            La sécurisation de ces métaux représente un enjeu de souveraineté majeur ainsi qu’une opportunité <span className="metals__paragraph_bold">d’épargne exceptionnelle pour les 30 ans à venir</span>
            </p>
            </div>
            <div className='metals__subtitle metals__subtitle_up'>
            <a href="/articles" className='metals__link-insights' target="_blank" rel="noopener noreferrer">
                Voyez aussi nos informations sur le marché <img src={arrow} alt="arrow" />
            </a>
            </div>
            </div>
            <div className='metals__graphics'>
            <TransitionGraph/>
            </div>
            </div>
            <div className='metals__subtitle  metals__subtitle_down'>
            <a href="/articles" className='metals__link-insights metals__link-insights_down' target="_blank" rel="noopener noreferrer">
                Voyez aussi nos informations sur le marché <img src={arrow} alt="arrow" />
            </a>
            </div>
        </section>
        </>
    )
}
