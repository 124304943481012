export const organizationSchema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "WeSafe",
        "url": "https://wesafe.store",
        "logo": "https://wesafe.store/static/media/logo_new.aff8f91e4ee24826af57.jpg",
        "email": "contact@wesafe.store",
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+33 (0) 1 83 64 66 18",
            "email": "contact@wesafe.store",
            "contactType": "Customer Service",
            "areaServed": "EU",
        },
        "sameAs": [
            "https://www.linkedin.com/company/wesafe-store/",
            "https://x.com/WeSafeStore"
        ],
        "numberOfEmployees": {
          "@type": "QuantitativeValue",
          "minValue": 3,
          "maxValue": 10
        },
        "address": {
            "@type": "PostalAddress",
            "addressCountry": "FR"
        },
        "description": "Unique plateforme européenne d'épargne en matériaux critiques. Achetez des métaux critiques de la tranisition numérique, énergétique sous forme physique.",
        "foundingDate": "2024-11-03",
        "vatID": "FR00987708898",
        "iso6523Code": "0007:98770889800018",
        "naics": [
          {
            "@type": "DefinedTerm",
            "termCode": "523999",
            "inDefinedTermSet": "https://www.census.gov/naics/",
            "name": "Miscellaneous Financial Investment Activities"
          },
          {
            "@type": "DefinedTerm",
            "termCode": "493190",
            "inDefinedTermSet": "https://www.census.gov/naics/",
            "name": "Other Warehousing and Storage"
          },
          {
            "@type": "DefinedTerm",
            "termCode": "425120",
            "inDefinedTermSet": "https://www.census.gov/naics/",
            "name": "Wholesale Trade Agents and Brokers"
          }
        ]
    }

export const articlesSchema = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Nouvelles & Insights",
  "url": "https://wesafe.store/articles",
  "breadcrumb": {
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": "https://wesafe.store",
          "name": "Home"
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": "https://wesafe.store/articles",
          "name": "Le Marche"
        }
      }
    ]
  },
  // "mainEntity": {
  //   "@type": "ItemList",
  //   "itemListElement": [
  //     {
  //       "@type": "Article",
  //       "headline": "Les métaux critiques et l'impact sur les véhicules électriques",
  //       "image": "https://wesafe.store/static/media/car1.185af6607b97e2999daa.jpg",
  //       "datePublished": "2024-05-12",
  //       // "author": {
  //       //   "@type": "Person",
  //       //   "name": "Author Name"
  //       // },
  //       "publisher": {
  //         "@type": "Organization",
  //         "name": "WeSafe Store",
  //         "logo": {
  //           "@type": "ImageObject",
  //           "url": "https://wesafe.store/static/media/logo_new.aff8f91e4ee24826af57.jpg"
  //         }
  //       },
  //       "description": "L'augmentation de la demande pour les véhicules électriques pourrait entraîner une augmentation de 30 fois des besoins en métaux critiques.",
  //       "url": "https://wesafe.store/articles/12-05-2024/les-métaux-critiques-et-l'impact-sur-les-véhicules-électriques"
  //     },
  //     {
  //      "@type": "NewsArticle",
  //       "headline": "Les prix du cuivre ont franchi le seuil des 10 000 dollars la tonne",
  //       "image": "https://wesafe.store/static/media/linechart.133745c6c417766306be.jpeg",
  //       "datePublished": "2024-04-26",
  //       // "author": {
  //       //   "@type": "Person",
  //       //   "name": "Author Name"
  //       // },
  //       "publisher": {
  //         "@type": "Organization",
  //         "name": "WeSafe Store",
  //         "logo": {
  //           "@type": "ImageObject",
  //           "url": "https://wesafe.store/static/media/logo_new.aff8f91e4ee24826af57.jpg"
  //         }
  //       },
  //       "description": "Les cours du cuivre ont grimpé au-dessus des 10 000 dollars la première fois en deux ans.",
  //       "url": "https://wesafe.store/articles/26-04-2024/les-prix-du-cuivre-ont-franchi-le-seuil-des-10-000-dollars-la-tonne"
  //     },
  //     {
  //       "@type": "Article",
  //       "headline": "France 2030 et métaux critiques",
  //       "image": "https://wesafe.store/static/media/france.fb45768c8d69b81c79ad.jpg",
  //       "datePublished": "2024-04-14",
  //       // "author": {
  //       //   "@type": "Person",
  //       //   "name": "Author Name"
  //       // },
  //       "publisher": {
  //         "@type": "Organization",
  //         "name": "WeSafe Store",
  //         "logo": {
  //           "@type": "ImageObject",
  //           "url": "https://wesafe.store/static/media/logo_new.aff8f91e4ee24826af57.jpg"
  //         }
  //       },
  //       "description": "Quelle est la stratégie de la France pour sécuriser son accès aux métaux critiques?",
  //       "url": "https://wesafe.store/articles/14-04-2024/france-2030-et-métaux-critiques"
  //     }
  //   ]
  // }
}

