const production = {
"children": [
      {"name": "Chili", "value": 5.330500, "objectives": "<b>5330500</b>:<br>12%<br>"},
      {"name": "Pérou", "value": 2.438631, "objectives": "<b>2438631</b>:<br>12%<br>"},
      {"name": "RD Congo", "value": 2.394630, "objectives": "<b>2394630</b>:<br>12%<br>"},
      {"name": "Chine", "value": 1.937800, "objectives": "<b>1937800</b>:<br>12%<br>"},
      {"name": "Pologne", "value": 0.443000, "objectives": "<b>443000</b>:<br>12%<br>"},
      {"name": "Russie", "value": 0.948000, "objectives": "<b>948000</b>:<br>12%<br>"},
      {"name": "Zambie", "value": 0.763550, "objectives": "<b>763550</b>:<br>12%<br>"},
      {"name": "Canada", "value": 0.519691, "objectives": "<b>519691</b>:<br>12%<br>"},
      {"name": "Mexique", "value": 0.828180, "objectives": "<b>828180</b>:<br>12%<br>"},
      {"name": "Panama", "value": 0.350438, "objectives": "<b>350438</b>:<br>12%<br>"},
      {"name": "États-Unis", "value": 1.100000, "objectives": "<b>1100000</b>:<br>12%<br>"},
      {"name": "Brésil", "value": 0.297653, "objectives": "<b>297653</b>:<br>12%<br>"},
      {"name": "Indonésie", "value": 0.965600, "objectives": "<b>965600</b>:<br>12%<br>"},
      {"name": "Iran", "value": 0.344200, "objectives": "<b>344200</b>:<br>12%<br>"},
      {"name": "Kazakhstan", "value": 0.589800, "objectives": "<b>589800</b>:<br>12%<br>"},
      {"name": "Mongolie", "value": 0.416430, "objectives": "<b>416430</b>:<br>12%<br>"},
      {"name": "Australie", "value": 0.818816, "objectives": "<b>818816</b>:<br>12%<br>"},
      {"name": "Autres", "value": 1.713081, "objectives": "<b>1713081</b>:<br>12%<br>"},
    ],
    "name": "Monde"
  };
  
  export default production;
  