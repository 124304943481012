const data =
[
{
"date": "1990-01",
"value": 1433
},
{
"date": "1990-02",
"value": 1392
},
{
"date": "1990-03",
"value": 1619
},
{
"date": "1990-04",
"value": 1641
},
{
"date": "1990-05",
"value": 1625
},
{
"date": "1990-06",
"value": 1511
},
{
"date": "1990-07",
"value": 1530
},
{
"date": "1990-08",
"value": 1555
},
{
"date": "1990-09",
"value": 1615
},
{
"date": "1990-10",
"value": 1410
},
{
"date": "1990-11",
"value": 1316
},
{
"date": "1990-12",
"value": 1293
},
{
"date": "1991-01",
"value": 1266
},
{
"date": "1991-02",
"value": 1245
},
{
"date": "1991-03",
"value": 1323
},
{
"date": "1991-04",
"value": 1412
},
{
"date": "1991-05",
"value": 1338
},
{
"date": "1991-06",
"value": 1346
},
{
"date": "1991-07",
"value": 1354
},
{
"date": "1991-08",
"value": 1326
},
{
"date": "1991-09",
"value": 1346
},
{
"date": "1991-10",
"value": 1372
},
{
"date": "1991-11",
"value": 1337
},
{
"date": "1991-12",
"value": 1217
},
{
"date": "1992-01",
"value": 1182
},
{
"date": "1992-02",
"value": 1241
},
{
"date": "1992-03",
"value": 1292
},
{
"date": "1992-04",
"value": 1261
},
{
"date": "1992-05",
"value": 1225
},
{
"date": "1992-06",
"value": 1240
},
{
"date": "1992-07",
"value": 1314
},
{
"date": "1992-08",
"value": 1295
},
{
"date": "1992-09",
"value": 1307
},
{
"date": "1992-10",
"value": 1359
},
{
"date": "1992-11",
"value": 1414
},
{
"date": "1992-12",
"value": 1423
},
{
"date": "1993-01",
"value": 1473
},
{
"date": "1993-02",
"value": 1536
},
{
"date": "1993-03",
"value": 1473
},
{
"date": "1993-04",
"value": 1265
},
{
"date": "1993-05",
"value": 1161
},
{
"date": "1993-06",
"value": 1229
},
{
"date": "1993-07",
"value": 1927
},
{
"date": "1993-08",
"value": 1948
},
{
"date": "1993-09",
"value": 1862
},
{
"date": "1993-10",
"value": 1646
},
{
"date": "1993-11",
"value": 1630
},
{
"date": "1993-12",
"value": 1727
},
{
"date": "1994-01",
"value": 1805
},
{
"date": "1994-02",
"value": 1866
},
{
"date": "1994-03",
"value": 1915
},
{
"date": "1994-04",
"value": 1883
},
{
"date": "1994-05",
"value": 2152
},
{
"date": "1994-06",
"value": 2364
},
{
"date": "1994-07",
"value": 2454
},
{
"date": "1994-08",
"value": 2406
},
{
"date": "1994-09",
"value": 2506
},
{
"date": "1994-10",
"value": 2548
},
{
"date": "1994-11",
"value": 2803
},
{
"date": "1994-12",
"value": 2985
},
{
"date": "1995-01",
"value": 3009
},
{
"date": "1995-02",
"value": 2878
},
{
"date": "1995-03",
"value": 2924
},
{
"date": "1995-04",
"value": 2904
},
{
"date": "1995-05",
"value": 2773
},
{
"date": "1995-06",
"value": 2995
},
{
"date": "1995-07",
"value": 3076
},
{
"date": "1995-08",
"value": 3037
},
{
"date": "1995-09",
"value": 2916
},
{
"date": "1995-10",
"value": 2814
},
{
"date": "1995-11",
"value": 2977
},
{
"date": "1995-12",
"value": 2926
},
{
"date": "1996-01",
"value": 2616
},
{
"date": "1996-02",
"value": 2538
},
{
"date": "1996-03",
"value": 2561
},
{
"date": "1996-04",
"value": 2591
},
{
"date": "1996-05",
"value": 2658
},
{
"date": "1996-06",
"value": 2173
},
{
"date": "1996-07",
"value": 1986
},
{
"date": "1996-08",
"value": 2009
},
{
"date": "1996-09",
"value": 1942
},
{
"date": "1996-10",
"value": 1961
},
{
"date": "1996-11",
"value": 2231
},
{
"date": "1996-12",
"value": 2268
},
{
"date": "1997-01",
"value": 2435
},
{
"date": "1997-02",
"value": 2406
},
{
"date": "1997-03",
"value": 2421
},
{
"date": "1997-04",
"value": 2391
},
{
"date": "1997-05",
"value": 2514
},
{
"date": "1997-06",
"value": 2613
},
{
"date": "1997-07",
"value": 2451
},
{
"date": "1997-08",
"value": 2251
},
{
"date": "1997-09",
"value": 2107
},
{
"date": "1997-10",
"value": 2052
},
{
"date": "1997-11",
"value": 1917
},
{
"date": "1997-12",
"value": 1762
},
{
"date": "1998-01",
"value": 1688
},
{
"date": "1998-02",
"value": 1665
},
{
"date": "1998-03",
"value": 1748
},
{
"date": "1998-04",
"value": 1801
},
{
"date": "1998-05",
"value": 1733
},
{
"date": "1998-06",
"value": 1661
},
{
"date": "1998-07",
"value": 1651
},
{
"date": "1998-08",
"value": 1621
},
{
"date": "1998-09",
"value": 1647
},
{
"date": "1998-10",
"value": 1586
},
{
"date": "1998-11",
"value": 1574
},
{
"date": "1998-12",
"value": 1474
},
{
"date": "1999-01",
"value": 1431
},
{
"date": "1999-02",
"value": 1411
},
{
"date": "1999-03",
"value": 1378
},
{
"date": "1999-04",
"value": 1461
},
{
"date": "1999-05",
"value": 1516
},
{
"date": "1999-06",
"value": 1422
},
{
"date": "1999-07",
"value": 1640
},
{
"date": "1999-08",
"value": 1647
},
{
"date": "1999-09",
"value": 1750
},
{
"date": "1999-10",
"value": 1724
},
{
"date": "1999-11",
"value": 1727
},
{
"date": "1999-12",
"value": 1764
},
{
"date": "2000-01",
"value": 1844
},
{
"date": "2000-02",
"value": 1800
},
{
"date": "2000-03",
"value": 1739
},
{
"date": "2000-04",
"value": 1678
},
{
"date": "2000-05",
"value": 1785
},
{
"date": "2000-06",
"value": 1753
},
{
"date": "2000-07",
"value": 1799
},
{
"date": "2000-08",
"value": 1856
},
{
"date": "2000-09",
"value": 1960
},
{
"date": "2000-10",
"value": 1898
},
{
"date": "2000-11",
"value": 1795
},
{
"date": "2000-12",
"value": 1850
},
{
"date": "2001-01",
"value": 1790
},
{
"date": "2001-02",
"value": 1765
},
{
"date": "2001-03",
"value": 1739
},
{
"date": "2001-04",
"value": 1664
},
{
"date": "2001-05",
"value": 1682
},
{
"date": "2001-06",
"value": 1608
},
{
"date": "2001-07",
"value": 1525
},
{
"date": "2001-08",
"value": 1464
},
{
"date": "2001-09",
"value": 1426
},
{
"date": "2001-10",
"value": 1377
},
{
"date": "2001-11",
"value": 1427
},
{
"date": "2001-12",
"value": 1471
},
{
"date": "2002-01",
"value": 1504
},
{
"date": "2002-02",
"value": 1562
},
{
"date": "2002-03",
"value": 1605
},
{
"date": "2002-04",
"value": 1590
},
{
"date": "2002-05",
"value": 1595
},
{
"date": "2002-06",
"value": 1647
},
{
"date": "2002-07",
"value": 1589
},
{
"date": "2002-08",
"value": 1479
},
{
"date": "2002-09",
"value": 1478
},
{
"date": "2002-10",
"value": 1483
},
{
"date": "2002-11",
"value": 1582
},
{
"date": "2002-12",
"value": 1595
},
{
"date": "2003-01",
"value": 1647
},
{
"date": "2003-02",
"value": 1683
},
{
"date": "2003-03",
"value": 1659
},
{
"date": "2003-04",
"value": 1587
},
{
"date": "2003-05",
"value": 1648
},
{
"date": "2003-06",
"value": 1686
},
{
"date": "2003-07",
"value": 1710
},
{
"date": "2003-08",
"value": 1760
},
{
"date": "2003-09",
"value": 1789
},
{
"date": "2003-10",
"value": 1920
},
{
"date": "2003-11",
"value": 2055
},
{
"date": "2003-12",
"value": 2201
},
{
"date": "2004-01",
"value": 2423
},
{
"date": "2004-02",
"value": 2759
},
{
"date": "2004-03",
"value": 3008
},
{
"date": "2004-04",
"value": 2947
},
{
"date": "2004-05",
"value": 2733
},
{
"date": "2004-06",
"value": 2686
},
{
"date": "2004-07",
"value": 2807
},
{
"date": "2004-08",
"value": 2845
},
{
"date": "2004-09",
"value": 2894
},
{
"date": "2004-10",
"value": 3011
},
{
"date": "2004-11",
"value": 3122
},
{
"date": "2004-12",
"value": 3145
},
{
"date": "2005-01",
"value": 3169
},
{
"date": "2005-02",
"value": 3253
},
{
"date": "2005-03",
"value": 3379
},
{
"date": "2005-04",
"value": 3394
},
{
"date": "2005-05",
"value": 3248
},
{
"date": "2005-06",
"value": 3523
},
{
"date": "2005-07",
"value": 3614
},
{
"date": "2005-08",
"value": 3797
},
{
"date": "2005-09",
"value": 3857
},
{
"date": "2005-10",
"value": 4059
},
{
"date": "2005-11",
"value": 4269
},
{
"date": "2005-12",
"value": 4576
},
{
"date": "2006-01",
"value": 4734
},
{
"date": "2006-02",
"value": 4982
},
{
"date": "2006-03",
"value": 5102
},
{
"date": "2006-04",
"value": 6386
},
{
"date": "2006-05",
"value": 8044
},
{
"date": "2006-06",
"value": 7196
},
{
"date": "2006-07",
"value": 7711
},
{
"date": "2006-08",
"value": 7694
},
{
"date": "2006-09",
"value": 7601
},
{
"date": "2006-10",
"value": 7499
},
{
"date": "2006-11",
"value": 7028
},
{
"date": "2006-12",
"value": 6673
},
{
"date": "2007-01",
"value": 5669
},
{
"date": "2007-02",
"value": 5675
},
{
"date": "2007-03",
"value": 6451
},
{
"date": "2007-04",
"value": 7765
},
{
"date": "2007-05",
"value": 7681
},
{
"date": "2007-06",
"value": 7474
},
{
"date": "2007-07",
"value": 7973
},
{
"date": "2007-08",
"value": 7511
},
{
"date": "2007-09",
"value": 7648
},
{
"date": "2007-10",
"value": 8007
},
{
"date": "2007-11",
"value": 6966
},
{
"date": "2007-12",
"value": 6587
},
{
"date": "2008-01",
"value": 7060
},
{
"date": "2008-02",
"value": 7887
},
{
"date": "2008-03",
"value": 8438
},
{
"date": "2008-04",
"value": 8684
},
{
"date": "2008-05",
"value": 8382
},
{
"date": "2008-06",
"value": 8260
},
{
"date": "2008-07",
"value": 8413
},
{
"date": "2008-08",
"value": 7631
},
{
"date": "2008-09",
"value": 6990
},
{
"date": "2008-10",
"value": 4925
},
{
"date": "2008-11",
"value": 3716
},
{
"date": "2008-12",
"value": 3071
},
{
"date": "2009-01",
"value": 3220
},
{
"date": "2009-02",
"value": 3314
},
{
"date": "2009-03",
"value": 3749
},
{
"date": "2009-04",
"value": 4406
},
{
"date": "2009-05",
"value": 4568
},
{
"date": "2009-06",
"value": 5013
},
{
"date": "2009-07",
"value": 5215
},
{
"date": "2009-08",
"value": 6165
},
{
"date": "2009-09",
"value": 6196
},
{
"date": "2009-10",
"value": 6287
},
{
"date": "2009-11",
"value": 6675
},
{
"date": "2009-12",
"value": 6985
},
{
"date": "2010-01",
"value": 7386
},
{
"date": "2010-02",
"value": 6848
},
{
"date": "2010-03",
"value": 7462
},
{
"date": "2010-04",
"value": 7744
},
{
"date": "2010-05",
"value": 6837
},
{
"date": "2010-06",
"value": 6499
},
{
"date": "2010-07",
"value": 6735
},
{
"date": "2010-08",
"value": 7283
},
{
"date": "2010-09",
"value": 7709
},
{
"date": "2010-10",
"value": 8292
},
{
"date": "2010-11",
"value": 8469
},
{
"date": "2010-12",
"value": 9147
},
{
"date": "2011-01",
"value": 9555
},
{
"date": "2011-02",
"value": 9867
},
{
"date": "2011-03",
"value": 9530
},
{
"date": "2011-04",
"value": 9483
},
{
"date": "2011-05",
"value": 8927
},
{
"date": "2011-06",
"value": 9045
},
{
"date": "2011-07",
"value": 9619
},
{
"date": "2011-08",
"value": 9041
},
{
"date": "2011-09",
"value": 8314
},
{
"date": "2011-10",
"value": 7347
},
{
"date": "2011-11",
"value": 7551
},
{
"date": "2011-12",
"value": 7568
},
{
"date": "2012-01",
"value": 8043
},
{
"date": "2012-02",
"value": 8422
},
{
"date": "2012-03",
"value": 8457
},
{
"date": "2012-04",
"value": 8259
},
{
"date": "2012-05",
"value": 7919
},
{
"date": "2012-06",
"value": 7420
},
{
"date": "2012-07",
"value": 7589
},
{
"date": "2012-08",
"value": 7492
},
{
"date": "2012-09",
"value": 8068
},
{
"date": "2012-10",
"value": 8069
},
{
"date": "2012-11",
"value": 7694
},
{
"date": "2012-12",
"value": 7962
},
{
"date": "2013-01",
"value": 8049
},
{
"date": "2013-02",
"value": 8070
},
{
"date": "2013-03",
"value": 7662
},
{
"date": "2013-04",
"value": 7203
},
{
"date": "2013-05",
"value": 7229
},
{
"date": "2013-06",
"value": 7004
},
{
"date": "2013-07",
"value": 6893
},
{
"date": "2013-08",
"value": 7182
},
{
"date": "2013-09",
"value": 7161
},
{
"date": "2013-10",
"value": 7188
},
{
"date": "2013-11",
"value": 7066
},
{
"date": "2013-12",
"value": 7203
},
{
"date": "2014-01",
"value": 7294
},
{
"date": "2014-02",
"value": 7152
},
{
"date": "2014-03",
"value": 6668
},
{
"date": "2014-04",
"value": 6668
},
{
"date": "2014-05",
"value": 6881
},
{
"date": "2014-06",
"value": 6806
},
{
"date": "2014-07",
"value": 7104
},
{
"date": "2014-08",
"value": 7000
},
{
"date": "2014-09",
"value": 6872
},
{
"date": "2014-10",
"value": 6739
},
{
"date": "2014-11",
"value": 6701
},
{
"date": "2014-12",
"value": 6425
},
{
"date": "2015-01",
"value": 5815
},
{
"date": "2015-02",
"value": 5702
},
{
"date": "2015-03",
"value": 5926
},
{
"date": "2015-04",
"value": 6028
},
{
"date": "2015-05",
"value": 6300
},
{
"date": "2015-06",
"value": 5833
},
{
"date": "2015-07",
"value": 5456
},
{
"date": "2015-08",
"value": 5089
},
{
"date": "2015-09",
"value": 5207
},
{
"date": "2015-10",
"value": 5222
},
{
"date": "2015-11",
"value": 4808
},
{
"date": "2015-12",
"value": 4629
},
{
"date": "2016-01",
"value": 4462
},
{
"date": "2016-02",
"value": 4595
},
{
"date": "2016-03",
"value": 4947
},
{
"date": "2016-04",
"value": 4851
},
{
"date": "2016-05",
"value": 4708
},
{
"date": "2016-06",
"value": 4630
},
{
"date": "2016-07",
"value": 4855
},
{
"date": "2016-08",
"value": 4758
},
{
"date": "2016-09",
"value": 4707
},
{
"date": "2016-10",
"value": 4732
},
{
"date": "2016-11",
"value": 5443
},
{
"date": "2016-12",
"value": 5666
},
{
"date": "2017-01",
"value": 5737
},
{
"date": "2017-02",
"value": 5941
},
{
"date": "2017-03",
"value": 5821
},
{
"date": "2017-04",
"value": 5697
},
{
"date": "2017-05",
"value": 5591
},
{
"date": "2017-06",
"value": 5699
},
{
"date": "2017-07",
"value": 5978
},
{
"date": "2017-08",
"value": 6478
},
{
"date": "2017-09",
"value": 6583
},
{
"date": "2017-10",
"value": 6798
},
{
"date": "2017-11",
"value": 6825
},
{
"date": "2017-12",
"value": 6801
},
{
"date": "2018-01",
"value": 7080
},
{
"date": "2018-02",
"value": 7001
},
{
"date": "2018-03",
"value": 6795
},
{
"date": "2018-04",
"value": 6838
},
{
"date": "2018-05",
"value": 6821
},
{
"date": "2018-06",
"value": 6954
},
{
"date": "2018-07",
"value": 6248
},
{
"date": "2018-08",
"value": 6039
},
{
"date": "2018-09",
"value": 6020
},
{
"date": "2018-10",
"value": 6215
},
{
"date": "2018-11",
"value": 6192
},
{
"date": "2018-12",
"value": 6094
},
{
"date": "2019-01",
"value": 5932
},
{
"date": "2019-02",
"value": 6278
},
{
"date": "2019-03",
"value": 6450
},
{
"date": "2019-04",
"value": 6445
},
{
"date": "2019-05",
"value": 6028
},
{
"date": "2019-06",
"value": 5868
},
{
"date": "2019-07",
"value": 5939
},
{
"date": "2019-08",
"value": 5708
},
{
"date": "2019-09",
"value": 5745
},
{
"date": "2019-10",
"value": 5742
},
{
"date": "2019-11",
"value": 5859
},
{
"date": "2019-12",
"value": 6062
},
{
"date": "2020-01",
"value": 6049
},
{
"date": "2020-02",
"value": 5686
},
{
"date": "2020-03",
"value": 5178
},
{
"date": "2020-04",
"value": 5048
},
{
"date": "2020-05",
"value": 5234
},
{
"date": "2020-06",
"value": 5742
},
{
"date": "2020-07",
"value": 6354
},
{
"date": "2020-08",
"value": 6497
},
{
"date": "2020-09",
"value": 6712
},
{
"date": "2020-10",
"value": 6703
},
{
"date": "2020-11",
"value": 7063
},
{
"date": "2020-12",
"value": 7755
},
{
"date": "2021-01",
"value": 7971
},
{
"date": "2021-02",
"value": 8460
},
{
"date": "2021-03",
"value": 9005
},
{
"date": "2021-04",
"value": 9336
},
{
"date": "2021-05",
"value": 10184
},
{
"date": "2021-06",
"value": 9612
},
{
"date": "2021-07",
"value": 9434
},
{
"date": "2021-08",
"value": 9357
},
{
"date": "2021-09",
"value": 9324
},
{
"date": "2021-10",
"value": 9777
},
{
"date": "2021-11",
"value": 9765
},
{
"date": "2021-12",
"value": 9549
},
{
"date": "2022-01",
"value": 9775
},
{
"date": "2022-02",
"value": 9940
},
{
"date": "2022-03",
"value": 10237
},
{
"date": "2022-04",
"value": 10182
},
{
"date": "2022-05",
"value": 9362
},
{
"date": "2022-06",
"value": 9032
},
{
"date": "2022-07",
"value": 7529
},
{
"date": "2022-08",
"value": 7960
},
{
"date": "2022-09",
"value": 7734
},
{
"date": "2022-10",
"value": 7620
},
{
"date": "2022-11",
"value": 8029
},
{
"date": "2022-12",
"value": 8367
},
{
"date": "2023-01",
"value": 8999
},
{
"date": "2023-02",
"value": 8954
},
{
"date": "2023-03",
"value": 8835
},
{
"date": "2023-04",
"value": 8813
},
{
"date": "2023-05",
"value": 8234
},
{
"date": "2023-06",
"value": 8386
},
{
"date": "2023-07",
"value": 8445
},
{
"date": "2023-08",
"value": 8351
},
{
"date": "2023-09",
"value": 8270
},
{
"date": "2023-10",
"value": 7939
},
{
"date": "2023-11",
"value": 8174
},
{
"date": "2023-12",
"value": 8394
},
{
"date": "2024-01",
"value": 8344
},
{
"date": "2024-02",
"value": 8310
},
{
"date": "2024-03",
"value": 8675
},
{
"date": "2024-04",
"value": 9482
},
{
"date": "2024-05",
"value": 10128
},
{
"date": "2024-06",
"value": 9641
}
]

export default data;