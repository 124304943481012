import './AboutSolution.css';
import { React, useState, useRef, useEffect } from "react";

export default function AboutSolution() {
    const [visibleItems, setVisibleItems] = useState({});
    const picRef = useRef(null);
    const itemRefs = useRef([]); // To store refs of each solution__item

    useEffect(() => {
        // Observer for the graph
        const picObserver = new IntersectionObserver(([entry]) => {
            setVisibleItems((prev) => ({ ...prev, graph: entry.isIntersecting }));
        }, { rootMargin: "0px" });

        if (picRef.current) {
            picObserver.observe(picRef.current);
        }

        // Observer for the solution items
        const itemObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setVisibleItems((prev) => ({ ...prev, [entry.target.dataset.index]: true }));
                }
            });
        }, { rootMargin: "0px", threshold: 0.1 });

        itemRefs.current.forEach((item, index) => {
            if (item) {
                itemObserver.observe(item);
            }
        });

        return () => {
            picObserver.disconnect();
            itemRefs.current.forEach((item) => {
                if (item) {
                    itemObserver.unobserve(item);
                }
            });
        };
    }, []);

    return (
        <>
            <section className='solution'>
                <h2 className='solution__title'>{'Transformez votre épargne en métaux industriels de la transition numérique et énergétique'}</h2>
                <div className='solution__container'>
                    <ul className='solution__list'>
                        {["Épargne alternative", 
                          "Participation à l'amélioration des filières", 
                          "Création d'un stock pour les industriels de France", 
                          "Transparence"].map((text, index) => (
                            <li
                                key={index}
                                className={`solution__item ${visibleItems[index] ? 'solution__item_visible' : ''}`}
                                ref={(el) => itemRefs.current[index] = el}
                                data-index={index}
                            >
                                {text}
                            </li>
                        ))}
                    </ul>
                    <div
                        className={`solution__graph ${visibleItems.graph ? 'solution__graph_hover' : ''}`}
                        ref={picRef}
                    >
                    </div>
                </div>
            </section>
        </>
    );
}
