import React, { useState, useEffect, useRef } from "react";
import './AboutProblem.css';
import circle1 from '../../images/aboutproblem/circle1.svg';
import circle2 from '../../images/aboutproblem/circle2.svg';
import circle3 from '../../images/aboutproblem/circle3.svg';
import circle4 from '../../images/aboutproblem/circle4.svg';

export default function AboutProblem() {
    const sectionRef = useRef(null);
    const [isTitleVisible, setIsTitleVisible] = useState(false);
    const [visibleCards, setVisibleCards] = useState([]);
    const [isScrollPaused, setIsScrollPaused] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if (entry.target.classList.contains('problem__title')) {
                            setIsTitleVisible(true);
                        } else {
                            const index = entry.target.dataset.index;
                            setVisibleCards((prevVisibleCards) => [...prevVisibleCards, index]);
                        }
                        observer.unobserve(entry.target); // Stop observing once visible
                    }
                });
            },
            { threshold: 0.1 } // Trigger when 10% of the element is visible
        );

        const cards = sectionRef.current.querySelectorAll('.problem__card');
        const title = sectionRef.current.querySelector('.problem__title');

        if (title) observer.observe(title);
        cards.forEach((card, index) => {
            card.dataset.index = index; // Add a unique index to each card
            observer.observe(card);
        });

        return () => {
            if (title) observer.unobserve(title);
            cards.forEach((card) => {
                observer.unobserve(card);
            });
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const section = sectionRef.current;
            const sectionTop = section.getBoundingClientRect().top;
            const sectionBottom = section.getBoundingClientRect().bottom;
            const viewportHeight = window.innerHeight;

            // Если пользователь прокручивает до конца секции
            if (sectionBottom <= viewportHeight && !isScrollPaused) {
                setIsScrollPaused(true);
                const scrollPosition = window.pageYOffset;

                // Фиксируем позицию прокрутки
                window.scrollTo(0, scrollPosition);

                // Разрешаем прокрутку через 2 секунды
                setTimeout(() => {
                    setIsScrollPaused(false);
                }, 2000);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isScrollPaused]);

    return (
        <section ref={sectionRef} className='problem'>
            <h2 className={`problem__title ${isTitleVisible ? 'problem__title_start' : ''}`}>
                {'Épargnez autrement...'}
            </h2>
            <div className='problem__container'>
                <div className={`problem__card ${visibleCards.includes('0') ? 'visible' : ''}`}>
                    <img className='problem__icon' src={circle1} alt='inflation'/>
                    <p className='problem__description'>
                        <span className="problem_description_bold">Achetez</span>{' des métaux critiques de la transition numérique, énergétique\nsous forme physique'}
                    </p>
                </div>
                <div className={`problem__card ${visibleCards.includes('1') ? 'visible' : ''}`}>
                    <img className='problem__icon' src={circle2} alt='access'/>
                    <p className='problem__description'>
                        <span className="problem_description_bold">Sortez</span> {'une partie de votre épargne des banques et transformez la en un actif réel'}
                    </p>
                </div>
                <div className={`problem__card ${visibleCards.includes('2') ? 'visible' : ''}`}>
                    <img className='problem__icon' src={circle3} alt='opportunities'/>
                    <p className='problem__description'>
                        <span className="problem_description_bold">Diluez</span> {'votre risque en achetant des métaux critiques sur un marché en dollars'}
                    </p>
                </div>
                <div className={`problem__card ${visibleCards.includes('3') ? 'visible' : ''}`}>
                    <img className='problem__icon' src={circle4} alt='opportunities'/>
                    <p className='problem__description'>
                        <span className="problem_description_bold">Stockez</span> {`des matières premières dont la demande va croître`}
                    </p>
                </div>
            </div>
        </section>
    );
}

