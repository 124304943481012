const ListIcon = ({ color, bccolor, selected, onClick }) => (
    <svg
      className="insights__view"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      onClick={onClick}
      style={{
        fill: bccolor,
        border: `2px solid #001F3D`,
        borderRadius: '5px',
        backgroundColor: selected ? '#001F3D' : 'white', // Set background color based on selection
        transition: 'background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease',
        boxShadow: `${selected ? '0 4px 8px rgba(0, 0, 0, 0.15)' : '0 1px 2px rgba(0, 0, 0, 0.05)'}`, // Stronger shadow if selected
        transform: `${selected ? 'scale(1.1)' : 'scale(1)'}`, // Slightly enlarge if selected
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="19" cy="21" r="5" style={{ fill: selected ? 'white' : '#001F3D' }} /> {/* Change color based on selection */}
      <circle cx="19" cy="38" r="5" style={{ fill: selected ? 'white' : '#001F3D' }} />
      <circle cx="19" cy="56" r="5" style={{ fill: selected ? 'white' : '#001F3D' }} />
      <rect x="30" y="16" width="36" height="8" rx="4" style={{ fill: selected ? 'white' : '#001F3D' }} />
      <rect x="30" y="34" width="36" height="8" rx="4" style={{ fill: selected ? 'white' : '#001F3D' }} />
      <rect x="30" y="52" width="36" height="8" rx="4" style={{ fill: selected ? 'white' : '#001F3D' }} />
    </svg>
  );
  
export default ListIcon
