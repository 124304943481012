
export const tooltipPosition = (event, selector, width) => {
    const tooltipWidth = parseInt(getComputedStyle(selector).width.replace("px", ""))
    if (window.screen.width-event.layerX > tooltipWidth + width/10) {
    return (event.layerX) + "px"}
    else if(width <800) {
      return `${0.2*width}px`
    }else{
      return (event.layerX-tooltipWidth) + "px"
    }
  }


export const tooltipPositionTransition = (event, selector, width) => {
  const tooltipWidth = parseInt(getComputedStyle(selector).width.replace("px", ""))
  // console.log(window.screen.width)
  // console.log(event.layerX)
  // console.log(tooltipWidth)
  if (window.screen.width-event.layerX*2 > tooltipWidth + width/5) {
  return (event.layerX) + "px"}
  else if(width <500) {
    return `${0.35*width}px`
  }else{
    return (event.layerX-tooltipWidth) + "px"
  }
}

  export const tooltipPositionY = (event, selector, elementTopPosition, elementHeight) => {
    const tooltipHeight = parseInt(getComputedStyle(selector).height.replace("px", ""))
    // console.log(tooltipHeight)
    // const rectPosition =  bottom + window.scrollY
    // console.log(elementTopPosition + elementHeight)
    // console.log(event.pageY)
    // console.log(elementTopPosition)
    // console.log(elementHeight)
    // console.log(event.clientY)
    // const elementBottomPosition = elementTopPosition + elementHeight
    // console.log(elementTopPosition)
    if (elementTopPosition + elementHeight - event.pageY < tooltipHeight) {
    return `${event.pageY+20}px`
    }else {
    return `${event.pageY+20}px`
    }
  }
    

