import React, { useState, useEffect } from 'react';
import 'react-phone-input-2/lib/style.css'; // Import the component's CSS
import PhoneInput from 'react-phone-input-2'; // Import the PhoneInput component
import { Helmet } from 'react-helmet-async';
import { URL } from '../../utils/constants/constants';
import image from '../../images/logo_new.jpg';
import './ContactUs.css';

export default function ContactUs() {
  const defaultData = {
    name: '',
    surname: '',
    email: '',
    phone: '',
    message: ''
  };

  const [data, setData] = useState(defaultData);
  const [clientMessage, setClientMessage] = useState(null);

  const handleData = (e) => {
    const { id, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [id]: value
    }));
  };

  // Update phone number separately using react-phone-input-2's value
  const handlePhoneChange = (phone) => {
    setData((prevData) => ({
      ...prevData,
      phone
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${URL}/contact-us`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      if (response.ok) {
        setClientMessage('Merci pour votre message. Il a été envoyé.');
        setData(defaultData); // Reset form
      } else {
        setClientMessage('Une erreur s’est produite lors de l’envoi de votre message. Veuillez essayer à nouveau plus tard.');
      }
    } catch (error) {
      console.error('Error:', error);
      setClientMessage('Erreur lors de la soumission du formulaire.');
    }
  };

  function handleClosePopup() {
    setClientMessage(null);
  }

  useEffect(() => {
    function onKeyup(e) {
      if (e.key === 'Escape') handleClosePopup();
    }
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, []);

  return (
    <>
      <Helmet>
        <title>Contactez-nous</title>
        <meta name="description" content="Contactez-nous pour plus d'information ici" />
        <link rel="canonical" href="https://wesafe.store/contact-us" />
        <meta name="og:description" content="Contactez-nous pour plus d'information ici" />
        <meta property="og:title" content="Contactez-nous" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://wesafe.store/contact-us"></meta>
        <meta property="og:image" content={image} />
        <meta property="og:site_name" content="WeSafe" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Contactez-nous" />
        <meta name="twitter:description" content="Contactez-nous pour plus d'information ici" />
        <meta name="twitter:image" content={image} />
      </Helmet>
      <section className="contact">
        <div className="contact__topic">
          <h1 className="contact__title">{`Contactez-nous pour investir, collaborer ou devenir client`}</h1>
        </div>
        <div className="contact__container">
          {/* <h2 className="contact__subtitle">{`Si vous souhaitez devenir investisseur, client potentiel ou\n discuter des formes de coopération, contactez-nous`}</h2> */}
          <form className="contact__form" onSubmit={handleSubmit}>
            <div className="contact__form-line">
              <div className="contact__field">
                <label className="contact__label" htmlFor="name">Prénom*</label>
                <input
                      type="text"
                      name="your-name"
                      id="name"
                      className="contact__input"
                      placeholder="Ex: Jean"
                      value={data.name}
                      onChange={(e) => handleData(e)}
                      required
                />
              </div>
              <div className="contact__field">
                <label className="contact__label" htmlFor="surname">Nom*</label>
                <input
                      type="text"
                      name="your-surname"
                      id="surname"
                      className="contact__input"
                      placeholder="Ex: Dupont"
                      value={data.surname}
                      onChange={(e) => handleData(e)}
                      required
                />
              </div>
            </div>
            <div className="contact__form-line">
              <div className="contact__field">
                <label className="contact__label" htmlFor="email">Adresse email*</label>
                <input
                      type="email"
                      name="your-email"
                      id="email"
                      className="contact__input"
                      placeholder="Ex: jean.dupont@email.com"
                      value={data.email}
                      onChange={(e) => handleData(e)}
                      required

                />
              </div>
              <div className="contact__field">
                <label className="contact__label" htmlFor="phone">Numéro de téléphone</label>
                <PhoneInput
                  country={'fr'}
                  value={data.phone}
                  onChange={handlePhoneChange}
                  inputClass="contact__input-phone"
                  dropdownClass="contact__dropdown"
                  placeholder="Ex: +33 6 12 34 56 78"
                />
              </div>
            </div>
            <div className="contact__field">
              <label className="contact__label" htmlFor="message">Message</label>
              <textarea
                id="message"
                className="contact__input-message"
                placeholder="Écrivez votre message ici..."
                value={data.message}
                onChange={(e) => handleData(e)}
              ></textarea>
            </div>
            <p type="text" className="contact__note">*Champ obligatoire</p>
            <button className="contact__submit" type="submit">Envoyer</button>
            {clientMessage === null ? null : (
              <div className="contact__popup" onClick={handleClosePopup}>{clientMessage}</div>
            )}
          </form>
        </div>
      </section>
    </>
  );
}
