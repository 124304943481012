import {React, useState}  from "react";
import { Link } from "react-router-dom";
import headerLogo from "../../images/logo_new.jpg"
import './Header.css';
import Navigation from '../Navigation/Navigation'
import linkedin from '../../images/linkedin_blue.png'


// https://www.google.com/search?q=position+for+dropdown+list
export default function Header() {
    const [isShowMenu, setIsShowMenu] = useState(false);
    const [active, setActive] = useState("");
    const handleActiveClick = (e) => {
        setActive(e.target.id);
      }

    const handleToggleBurgerMenu = () =>{
        setIsShowMenu(!isShowMenu);
    }
    const handleCloseBurgerMenu = () =>{
        setIsShowMenu(false);
    }

    return(
        <>
        <header className='header'>
            <Link to="/">
            <img id={"0"} className="header__logo" src={headerLogo} alt="logo" onClick={(e)=>{handleActiveClick(e) ; handleCloseBurgerMenu()}} />
            </Link>
            {/* <Link to={"https://www.linkedin.com/company/wesafe-store/"} target="_blank">
                <img className="header__linkedin-logo" src={linkedin} alt="linkedin" />
            </Link> */}
            {!isShowMenu ?
            <>
        <div className="header__navigation">
        <div className="header__network">
            <p className="header__phone">+33 (0) 1 83 64 66 18</p>
            <Link className="header__link" to={"https://www.linkedin.com/company/wesafe-store/"} target="_blank">
                <img className="header__linkedin-logo" src={linkedin} alt="linkedin" />
            </Link>
            </div>
            <div className="header__links-row">
            <Navigation active={active} handleActiveClick={handleActiveClick} />
            </div>
        </div>
            </>
            : 
            <div className="header__links-column">
            <div  className="header__links-column-container">
            <Navigation isShowMenu={isShowMenu} handleToggleBurgerMenu={handleToggleBurgerMenu} setIsShowMenu={setIsShowMenu} active={active} handleActiveClick={handleActiveClick} />
            </div>
            </div>
            }
        <button id="navigation" aria-label="menu"className = {`${isShowMenu ? 'header__burger-close-button' : 'header__burger-menu' }`} onClick={()=>handleToggleBurgerMenu()}></button>
        </header>
        </>
    )
}