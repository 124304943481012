import "./AboutUs.css"
import AboutTeam from '../AboutTeam/AboutTeam' 
import President from '../President/President'
import History from '../OurHistory/OurHistory'
import Partners from '../Partners/Partners'
import {Helmet} from 'react-helmet-async'
import image from "../../images/logo_new.jpg"

export default function AboutUs() {
    return(
        <>
        <Helmet>
            <title>Notre équipe</title>
            <meta name="description" content="Transformer une partie de l’épargne en matières premières de la transition écologique et numérique avec nous: Jerome Thomasse, Christophe Roy, Artem Zubov"></meta>
            <link rel="canonical" href="https://wesafe.store/about-us" />

            <meta property="og:url" content={`https://wesafe.store/about-us`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Le Marché" />
            <meta property="og:description" content="L'équipe WeSafe" />
            <meta property="og:image" content={image} />
            <meta property="og:site_name" content="WeSafe" />
            
            <meta name="twitter:card" content="summary" />
            {/* <meta name="twitter:site" content="@yourusername" /> */}
            <meta name="twitter:title" content="Notre équipe" />
            <meta name="twitter:description" content="Transformer une partie de l’épargne en matières premières de la transition écologique et numérique avec nous: Jerome Thomasse, Christophe Roy, Artem Zubov" />
            <meta name="twitter:image" content={image} />
        </Helmet>
        <section>
        <div className="us__topic">
            <h1 className="us__title">Notre Equipe</h1>
            </div>
            <AboutTeam/>
            {/* <President/> */}
            <History/>
            {/* <Partners/> */}
        </section>
        </>
    )
}
