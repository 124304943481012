import {React, useRef, useEffect} from 'react';
import { useResizeObserver } from '../../../utils/constants/useResizeObserver';
import { tooltipPosition, tooltipPositionY } from '../../../utils/constants/tooltipPosition';
import * as d3 from 'd3'
import { Link } from "react-router-dom";
import '../FrontendArticle.css';
import {dataPIA4, dataPIA3} from "../../../utils/data/franceData"
import timelineFrance2030 from '../../../images/articles/timeline.svg'


export default function France2030 () {
    const contTreeRef = useRef();
    const treeRef = useRef();
    const contTreeRef2 = useRef();
    const treeRef2 = useRef();
    const contDonutRef = useRef();
    const donutRef = useRef();
    const dimensionsTree = useResizeObserver(contTreeRef);
    const dimensionsTree2 = useResizeObserver(contTreeRef2);
    const dimensionsDonut = useResizeObserver(contDonutRef);

    const s = d3.formatSpecifier("f");
    s.precision = d3.precisionFixed(0);
    const p = d3.format(s)
    const locale = d3.formatLocale({
      decimal: ".",
      thousands: "\u00a0",
      grouping: [3]
    })
    const f = locale.format(",")
    // let x = parseInt(getComputedStyle("#front-article__treemap-title").left);
    // let x = parseInt(document.querySelector("#front-article__treemap-title").top);  
  
    useEffect(() =>{
        const treemapBottomY = document.querySelector(".front-article__treemap-container").offsetTop
        // const treemapTopY = document.querySelector(".front-article__treemap-container").getBoundingClientRect().top
        // console.log(treemapTopY)
        // console.log(treemapBottomY)
        d3.select("svg").remove()
        d3.select("svg").remove()
        d3.select("svg").remove()
          
        if (!dimensionsTree) return;

            const width = dimensionsTree.width
            const height = dimensionsTree.height
            const paddingTop = 15

        const root = d3.hierarchy(dataPIA4).sum(function(d){ return d.value}) // Here the size of each leave is given in the 'value' field in input data

        const svg = d3.select(treeRef.current)
        .append("svg")
        .attr("viewBox", '0 0 ' + width + ' ' + height)
        .attr("preserveAspectRatio", 'none')

        // Then d3.treemap computes the position of each element of the hierarchy
        d3.treemap()
          .size([width, height])
          .paddingTop(paddingTop)
          .paddingRight(8)
          .paddingInner(0)      // Padding between each rectangle
          //.paddingOuter(6)
          //.padding(20)
          (root)
        
        // prepare a color scale
        const color = d3.scaleOrdinal()
        .domain(["421", "422", "423"])
        .range([ "#B1ADAD", "#60794F", "#003468", "#646060", "#001F3D"])

        const opacity = d3.scaleLinear()
          .domain([10, 30])
          .range([.5,1])

          function wrapText(selection) {
            selection.each(function() {
                var text = d3.select(this),
                words = text.text().split(/\s+/).reverse(),
                word,
                line = [],
                lineNumber = 0,
                lineHeight = 1.1, // ems
                x = text.attr("x"),
                y = text.attr("y"),
                dy = 0,
                tspan = text.text(null).append("tspan").attr("x", x).attr("y", y)
                const rectWidth = +text.attr('width')
                const rectHeight = +text.attr('height')
                const value = d3.select(this).datum().value

                // const fontSize = Math.max(Math.min(rectWidth/17.5, Math.sqrt((rectWidth*rectWidth))/10), 8)
                const fontSize = Math.max(Math.min(rectWidth/5, rectHeight/2, Math.sqrt((rectWidth*rectWidth + rectHeight*rectHeight))/28), 9)
                // console.log(fontSize)
                text.attr("font-size", fontSize)

            if (rectWidth < 56) {
                line.pop();
                tspan.text(line.join(" "));
                tspan = text.append("tspan").attr("x", x).attr("y", y).text('...')   
                }else{
                while(word = words.pop()) {
                    line.push(word);
                    tspan.text(line.join(" "));
                if (tspan.node().getComputedTextLength() > rectWidth && line.length !== 1) {
                  line.pop();
                  tspan.text(line.join(" "));
                  line = [word];
                  tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word)
                }
              }
          }
          if (rectWidth < 56){
            tspan = text.append('tspan').attr("x", x).attr("y", y).text('')
          } else {
            tspan = text.append('tspan').attr("x", x).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(value)
          }
        })
        }

    //     function wrapTitle(selection) {
    //         selection.each(function() {
    //             var text = d3.select(this),
    //             words = text.text().split(/\s+/).reverse(),
    //             word,
    //             line = [],
    //             lineNumber = 0,
    //             lineHeight = 1.1, // ems
    //             x = text.attr("x"),
    //             y = text.attr("y"),
    //             dy = 0,
    //             tspan = text.text(null).append("tspan").attr("x", x).attr("y", y)
    //             const rectWidth = +text.attr('width')
    //             const value = d3.select(this).datum().value

    //         if (rectWidth < 150) {
    //             line.pop();
    //             tspan.text(line.join(" "));
    //             tspan = text.append("tspan").attr("x", x).attr("y", y).text('...')   
    //             }else{
    //             while(word = words.pop()) {
    //                 line.push(word);
    //                 tspan.text(line.join(" "))
    //             if (tspan.node().getComputedTextLength() > rectWidth && line.length !== 1) {
    //               line.pop();
    //               tspan.text(line.join(" "));
    //               line = [word];
    //               tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word)
    //             }
    //           }
    //       }
    //     })
    // }
        
        
      
        // use this information to add rectangles:
        const tooltip = d3.select(treeRef.current)
        .append("div")
        // .style("position", "absolute")
        .style("opacity", 0)
        .style("display", "none")
        .attr("class", "front-article__treemap-tooltip")

        const tooltipFrance = document.querySelector('.front-article__treemap-tooltip')

        function addTooltip(selection) {
            selection.on("click", function(event, d){
                tooltip
                  .style("opacity", 1)
                  .style("display", "block")
                })
              .on("mouseover", function(event,d) {
                  tooltip
                  .style("opacity", 0)
                  .style("display", "none")
                  })
              .on("mousemove", function(event,d) {
                  tooltip
                  .style("opacity", 1)
                  .style("display", "block")
                  .html("<b>Titre:</b> "+d.data.name +"<br>" + "<b>AE:</b> "+(f(p(d.data.value)))+" M€" +"<br>"+ "<b>Responsables</b>:"+ d.data.responsible +"<br>" +"<br>" + d.data.objectives)
                  .style("left", tooltipPosition(event, tooltipFrance, width))
                  .style("top", tooltipPositionY(event, tooltipFrance, treemapBottomY, height/2))
                  .transition()
                  // .duration(20)
                  })
              .on("mouseleave", function(d) {
                  tooltip
                  // .transition()
                  // .duration(20)
                  .style("opacity", 0)
                  .style("display", "none")
                  })
        }
            

        svg
          .selectAll("rect")
          .data(root.leaves())
          .join("rect")
            .attr('x', function (d) { return d.x0; })
            .attr('y', function (d) { return d.y0; })
            .attr('width', function (d) { return d.x1 - d.x0; })
            .attr('height', function (d) { return d.y1 - d.y0; })
            .attr("class", "front-article__rect")
            .style("fill", function(d){ return color(d.parent.data.name)} )
            .style("opacity", function(d){ return opacity(d.data.value)})
            .call(addTooltip)

            svg
            .selectAll('text')
            .data(root.leaves())
            .enter()
            .append('text')
            .text((d) => d.data.name)
            .attr('width', function (d) { return d.x1 - d.x0; })
            .attr('height', function (d) { return d.y1 - d.y0; })
            .attr("x", function(d){ return d.x0+(d.x1-d.x0)/2})    // +10 to adjust position (more right)
            .attr("y", function(d){ return d.y0+(d.y1-d.y0)/3})    // +20 to adjust position (lower)
            .call(wrapText)
            .attr("class", "front-article__text")
            .call(addTooltip)

          // Add title for the 3 groups
          svg
            .selectAll("titles")
            .data(root.descendants().filter(function(d){return d.depth==1}))
            .enter()
            .append("text")
              .attr("x", function(d){ return d.x0})
              .attr("y", function(d){ return d.y0+paddingTop-4})
              .attr('width', function (d) { return d.x1 - d.x0; })
              //.attr("y", function(d, i){ return 0+ i*10 + 21})
              .text(function(d){ return d.data.name})
              .attr("class", "front-article__title")
              .attr("fill",  function(d){ return color(d.data.name)} )
            //   .call(wrapTitle)
        },[dimensionsTree])


        useEffect(() => {

        const data =
            [{"data": ["Banque publique d'investissement (BPI)", 24.6, "BPI", 24.6 ], "endAngle": 2.883700719339885 , "index":0, "padAngle": 0, "startAngle":0, "value": 24.6},
            {"data": ["Agence nationale de la recherche", 11.5, "ANR", 11.5], "endAngle": 4.231772193828043 , "index":1, "padAngle": 0, "startAngle":2.883700719339885, "value": 11.5},
            {"data": ["Agence de la transition écologique", 11.5, "Ademe", 11.5], "endAngle": 5.193005766941337 , "index":2, "padAngle": 0, "startAngle": 4.231772193828043, "value": 11.5},
            {"data": ["Reste à Affecter", 11.5, "Reste", 11.5], "endAngle": 5.9080697664524475 , "index":3, "padAngle": 0, "startAngle": 5.193005766941337, "value": 11.5},
            {"data": ["Autres (État)", 0.9, " ", " "], "endAngle": 6.283185307179587, "index":4, "padAngle": 0, "startAngle": 6.177684061350079, "value": 11.5},
            {"data": ["Caisse des Dépôts et Consignations", 2.3, "CDC", 2.3], "endAngle": 6.177684061350079 , "index":5, "padAngle": 0, "startAngle": 5.9080697664524475, "value": 11.5},
            ]


            if (!dimensionsDonut) return;
            const width = dimensionsDonut.width
            const height = dimensionsDonut.height
    
            const radius = Math.min(width, height) / 2
            
            const svg = d3.select(donutRef.current)
            .append("svg")
            .attr("viewBox", '0 0 ' + width + ' ' + height)
            .attr("preserveAspectRatio", 'none')
            .append("g")
            .attr("transform", `translate(${width / 2},${height / 2})`);
    
            const color = d3.scaleOrdinal()
            .range(["#001F3D", "#646060", "#385723", "#003468", "#B1ADAD", "#60794F"])
    
            // const pie = d3.pie()
            // .value(d=>d[1])
    
            // const data_ready = pie(Object.entries(data)

            const tooltip = d3.select(donutRef.current)
            .append("div")
            .style("position", "absolute")
            .style("opacity", 0)
            // .style("display", "none")
            .attr("class", "front-article__donut-tooltip")
    
            const tooltipDonut = document.querySelector('.front-article__donut-tooltip')
            
            //при нажатии срабатывают все функции, поэтому открывается тултип только на второй раз
            function addTooltip(selection) {
              selection.on("click", function(event, d){
                  tooltip
                    .style("opacity", 1)
                    .style("display", "block")
                  })
                .on("mouseover", function(event,d) {
                    tooltip
                    .style("opacity", 0)
                    .style("display", "none")
                    })
                .on("mousemove", function(event,d) {
                    tooltip
                    .style("opacity", 1)
                    .style("display", "block")
                    .html(d.data[0] +"<br>" + (f(p(d.data[1])))+" Mds€"+"  (" +((d.data[1])/53.7*100).toFixed(0)+" %)")
                    .style("left", tooltipPosition(event, tooltipDonut, width))
                    .style("top", `${event.layerY+10}px`)
                    .transition()
                    // .duration(20)
                    })
                .on("mouseleave", function(d) {
                    tooltip
                    // .transition()
                    // .duration(20)
                    .style("opacity", 0)
                    .style("display", "none")
                    })
          }

            const innerRadius = parseInt(d3.select('#france-donut').style('stroke-width')) 
            // shape helper to build arcs:
            var arcGenerator = d3.arc()
            .innerRadius(innerRadius)
            .outerRadius(radius)

            svg
            .selectAll('dots')
            .data(data)
            .join('path')
            .attr('d', arcGenerator)
            .attr('fill', d => color(d.data[0]))
            .attr("class" , "front-article__donut-chart")
            .call(addTooltip)


            svg
            .selectAll('text')
            .data(data)
            .enter()
            .append('text')
            .text((d) => d.data[2])
            .attr("transform", function(d) { return "translate(" + arcGenerator.centroid(d) + ")";  })
            .attr("class" , "front-article__donut-text")
            .call(addTooltip)

            svg
            .selectAll('values')
            .data(data)
            .enter()
            .append('text')
            .text((d) => d.data[3])
            // .attr("x", "-10")
            .attr("y", "16")
            .attr("transform", function(d) { return "translate(" + arcGenerator.centroid(d) + ")";  })
            .attr("class" , "front-article__donut-number")
            .call(addTooltip)
        },[dimensionsDonut])

    // Treemap2_PIA3
    useEffect(() =>{
      const treemapBottomY = document.querySelector(".front-article__treemap2-container").offsetTop
      // d3.select("svg").remove()
      // d3.select("svg").remove()
      // d3.select("svg").remove()
        
      if (!dimensionsTree2) return;

          const width = dimensionsTree2.width
          const height = dimensionsTree2.height
          const paddingTop = 15

      const root = d3.hierarchy(dataPIA3).sum(function(d){ return d.value}) // Here the size of each leave is given in the 'value' field in input data

      const svg = d3.select(treeRef2.current)
      .append("svg")
      .attr("viewBox", '0 0 ' + width + ' ' + height)
      .attr("preserveAspectRatio", 'none')

      // Then d3.treemap computes the position of each element of the hierarchy
      d3.treemap()
        .size([width, height])
        .paddingTop(paddingTop)
        .paddingRight(8)
        .paddingInner(0)      // Padding between each rectangle
        //.paddingOuter(6)
        //.padding(20)
        (root)
      
      // prepare a color scale
      const color = d3.scaleOrdinal()
      .domain(["421", "422", "423"])
      .range(["#001F3D", "#385723", "#646060"])

      const opacity = d3.scaleLinear()
        .domain([10, 30])
        .range([.5,1])

        const countWords = (words) =>{
          for (let i=0; i<words.length; i++) {
           words +=words[i];
           let spaces = words[i].length - 1 
           return words.length + spaces 
          }
        }

        function wrapText(selection) {
          selection.each(function() {
              var text = d3.select(this),
              words = text.text().split(/\s+/).reverse(),
              word,
              line = [],
              lineNumber = 0,
              lineHeight = 1.1, // ems
              x = text.attr("x"),
              y = text.attr("y"),
              dy = 0,
              tspan = text.text(null).append("tspan").attr("x", x).attr("y", y)
              const rectWidth = +text.attr('width')
              const rectHeight = +text.attr('height')/2
              const value = d3.select(this).datum().value

              // const fontSize = Math.max(Math.min(rectWidth/17.5, Math.sqrt((rectWidth*rectWidth))/10), 8)
              const fontSize = Math.max(Math.min(rectWidth/5*2, rectHeight*2, Math.sqrt((rectWidth*rectWidth + rectHeight*rectHeight)*2)/28), 9)
              // console.log(fontSize)
              text.attr("font-size", fontSize)
              // console.log(rectWidth*rectHeight)
              // console.log(rectWidth)
              // console.log(rectHeight)
              // console.log(countWords(words)*9*2)
              const wordHeight = 9;
              const wordWidth = 2 
              // console.log(countWords(words)*wordHeight*wordWidth<rectWidth*rectHeight)
              // console.log(words)
              // console.log(words.forEach((line) => line))

          if (rectWidth < 80 || rectHeight < 10) {
              line.pop();
              tspan.text(line.join(" "));
              tspan = text.append("tspan").attr("x", x).attr("y", y).text('...')
              
              }else if (countWords(words)*wordHeight*wordWidth>rectWidth*rectHeight){
                tspan = text.append("tspan").attr("x", x).attr("y", y).text('...')
              }else{
              while(word = words.pop()) {
                  line.push(word);
                  tspan.text(line.join(" "))
              if (tspan.node().getComputedTextLength() > rectWidth && line.length !== 1) {
                line.pop();
                tspan.text(line.join(" "));
                line = [word];
                tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word)
              } 
            }
        }
        if (rectWidth < 100){
          tspan = text.append('tspan').attr("x", x).attr("y", y).text('')
        } else if (rectHeight < 10){
          tspan = text.append("tspan").attr("x", x).attr("y", y).text('...')
          }
        else {
          tspan = text.append('tspan').attr("x", x).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(value)
        }
      })
      }
      // use this information to add rectangles:
      const tooltip = d3.select(treeRef2.current)
      .append("div")
      .style("opacity", 0)
      .style("display", "none")
      .attr("class", "front-article__treemap-tooltip2")

      const tooltipFrance = document.querySelector('.front-article__treemap-tooltip2')

      // console.log(height)
      function addTooltip(selection) {
          selection.on("click", function(event, d){
              tooltip
                .style("opacity", 1)
                .style("display", "block")
              })
            .on("mouseover", function(event,d) {
                tooltip
                .style("opacity", 0)
                .style("display", "none")
                })
            .on("mousemove", function(event,d) {
                tooltip
                .style("opacity", 1)
                .style("display", "block")
                .html("<b>Titre:</b> "+d.data.name +"<br>" + "<b>AE:</b> "+(f(p(d.data.value)))+" M€")
                .style("left", tooltipPosition(event, tooltipFrance, width))
                .style("top", tooltipPositionY(event, tooltipFrance, treemapBottomY, height/2))
                .transition()
                // .duration(20)
                })
            .on("mouseleave", function(d) {
                tooltip
                // .transition()
                // .duration(20)
                .style("opacity", 0)
                .style("display", "none")
                })
      }
          
      svg
        .selectAll("rect")
        .data(root.leaves())
        .join("rect")
          .attr('x', function (d) { return d.x0; })
          .attr('y', function (d) { return d.y0; })
          .attr('width', function (d) { return d.x1 - d.x0; })
          .attr('height', function (d) { return d.y1 - d.y0; })
          .attr("class", "front-article__rect")
          .style("fill", function(d){ return color(d.parent.data.name)} )
          .style("opacity", function(d){ return opacity(d.data.value)})
          .call(addTooltip)

          svg
          .selectAll('text')
          .data(root.leaves())
          .enter()
          .append('text')
          .text((d) => d.data.name)
          .attr('width', function (d) { return d.x1 - d.x0; })
          .attr('height', function (d) { return d.y1 - d.y0; })
          .attr("x", function(d){ return d.x0+(d.x1-d.x0)/2})    // +10 to adjust position (more right)
          .attr("y", function(d){ return d.y0+(d.y1-d.y0)/4})    // +20 to adjust position (lower)
          .call(wrapText)
          .attr("class", "front-article__text")
          .call(addTooltip)

        // Add title for the 3 groups
        svg
          .selectAll("titles")
          .data(root.descendants().filter(function(d){return d.depth==1}))
          .enter()
          .append("text")
            .attr("x", function(d){ return d.x0})
            .attr("y", function(d){ return d.y0+paddingTop-4})
            .attr('width', function (d) { return d.x1 - d.x0; })
            //.attr("y", function(d, i){ return 0+ i*10 + 21})
            .text(function(d){ return d.data.name })
            .attr("class", "front-article__title")
            .attr("fill",  function(d){ return color(d.data.name)} )
          //   .call(wrapTitle)
      },[dimensionsTree2])
    // and to add the text labels
    
    return (
        <>
        <p className='front-article__paragraph'>Depuis 2010, le gouvernement français a mis en place successivement quatre programmes d'investissement d'avenir (PIA). 
        Le PIA vise à financer des projets innovants et stratégiques qui contribueront à l'avenir du pays. 
        Il cherche à stimuler la compétitivité de la France, à renforcer son rayonnement à l'échelle internationale et à anticiper les défis à venir dans divers secteurs tels que l'innovation technologique, la recherche, l'éducation, la transition écologique, l'industrie.</p>
        <p className='front-article__paragraph'>
        En 2017, le gouvernement français a lancé le PIA 3, qui portait principalement sur les recherches et les études de haut niveau. Il consistait de trois programmes:
        </p>
        <ul className='front-article__list'>
        <li className='front-article__bullit'>№ 421 - Soutien des progrès de l’enseignement et de la recherche</li>
        <li className='front-article__bullit'>№ 422 - Valorisation de la recherche</li>
        <li className='front-article__bullit'>№ 423 - Accélération de la modernisation des entreprises</li>
        </ul>
        <p className='front-article__paragraph'>
        Chaque programme est constitué de plusieurs actions, chacune comportant plusieurs volets.
        </p>
        <h2 id='front-article__graph-title' className='front-article__graph-title'>{`Autorisation d’engagement de PIA 3\npar les programmes et les actions (Millions €) au 30/06/2023`}</h2>
        <p className='front-article__graph-title-hint'>{`[cliquez pour voir plus d'info]`}</p>
        <div className='front-article__treemap2-container' ref={contTreeRef2}>
        <div className='front-article__treemap2' ref={treeRef2}></div>
        </div>
        <p className='front-article__source'>Source: Rapport relatif à la mise en oeuvre et au suivi des investissements d'avenir, 2023</p>
        <p className='front-article__paragraph'>En 2021, la France a lancé PIA 4 pour un total de 20 milliards d'euros, comprenant deux programmes :</p>
        <ul className='front-article__list'>
         <li className='front-article__bullit'>№424 - "Financement des investissements stratégiques"</li>
         <li className='front-article__bullit'>№425 - "Financement structurel des écosystèmes d'innovation"</li>
         </ul>
         <p className='front-article__paragraph'>Désormais, « France 2030 » prolonge la démarche engagée afin de transformer les secteurs clés pour l'économie de demain.
         Après cela, le gouvernement a fusionné le PIA 4 avec la mission « France 2030 », en regroupant les programmes №424 et №425 en accord avec « France 2030 » dans le projet de budget 2022.</p>
        <img className='front-article__image' src={timelineFrance2030} alt="france2030"></img>
        <p className='front-article__paragraph'>Les projets du PIA 4 revêtent une importance considérable par rapport à ceux d'avant et se concentrent principalement sur l'amélioration de l'industrie d'avenir, 
            y compris l'intégration des technologies de la transition écologique de demain et le soutien de la production des métaux pour soutenir cette transition.</p>
        <p className='front-article__paragraph'>La mission de projet est mise en œuvre par les opérateurs, qui analysent le caractère innovant, le potentiel et les capacités des projets, ainsi que valident les assiettes de dépenses éligibles et contractualisent avec les bénéficiaires des aides.
         Les opérateurs sont les ministères et agences étatiques, notamment:</p>
        <ul className='front-article__list'>
        <li className='front-article__bullit'>Agence de la transition écologique (Ademe)</li>
        <li className='front-article__bullit'>Agence nationale de la recherche (ANR)</li>
        <li className='front-article__bullit'>BPI France (BPI)</li>
        <li className='front-article__bullit'>Caisse des Dépôts et Consignations (CDC)</li>
        </ul>
        <h2 className='front-article__graph-title'>{`Enveloppe pluriannuelle totale de « France 2030 »\npar opérateur (Mds€)`}</h2>
        <p className='front-article__graph-title-hint'>{`[cliquez pour voir plus d'info]`}</p>
        <div className='front-article__donut-container' ref={contDonutRef}>
        <div id='france-donut' className='front-article__donut' ref={donutRef}></div>
        <p className='front-article__donut-total'>{`Total\n53.7 Mds€`}</p>
        </div>
        <p className='front-article__source'>Source: Rapport relatif à la mise en oeuvre et au suivi des investissements d'avenir, 2023</p>
        <p className='front-article__paragraph'>
        Les programmes et actions de PIA4 ne se réfèrent pas directement à des domaines d'investissement, mais plutôt à des moyens de soutenir l'innovation.
        Le programme №424 regroupe tous les investissements sectoriels en fonction du niveau de maturité de la technologie et le programme №425 contribue aux financements structurels des écosystèmes d'innovation
        </p>
        <p className='front-article__paragraph'>
            La loi budgétaire de la France prévoit, ainsi, les crédits de paiement chaque année pour les programmes. 
        </p>
        <h2 id='front-article__treemap-title' className='front-article__graph-title'>{`Autorisation d’engagement de « France 2030 »\npar les programmes et les actions (Millions €) au 31/08/2023`}</h2>
        <p className='front-article__graph-title-hint'>{`[cliquez pour voir plus d'info]`}</p>
        <div className='front-article__treemap-container' ref={contTreeRef}>
        <div className='front-article__treemap' ref={treeRef}></div>
        </div>
        <p className='front-article__source'>Source: Rapport relatif à la mise en oeuvre et au suivi des investissements d'avenir, 2023</p>
        <p className='front-article__paragraph'>Parmi les projets au sein des investissements de l'avenir, il y a ceux qui influent directement sur la chaîne d'approvisionnement des métaux critiques en France:</p>
        <ul className='front-article__list'>
        <li className='front-article__bullit'>L'appel à projet (AAP) « Métaux critiques » (les programmes d’ “Industrialisation et déploiement” et de “Soutien au déploiement”)</li>
        <li className='front-article__bullit'>Fonds « Métaux critiques » (Aides à l’innovation «bottom-up» (fonds propres))</li>
        </ul>
        <p className='front-article__paragraph'>Et ceux, qui influent indirectement, notamment:</p>
        <ul className='front-article__list'>
        <li className='front-article__bullit'>AAP « I-démo » (action « Aides à l’innovation «bottom-up» (subventions et prêts) »)</li>
        <li className='front-article__bullit front-article__pre-wrap'>{`AAP « Solutions innovantes pour l’amélioration de la recyclabilité, le recyclage et la réincorporation des matériaux (RRR) »\n(action « Démonstration en conditions réelles, amorçage et premières commerciales »)`}</li>
        <li className='front-article__bullit'>L'appel à manifestation d’intérêt (AMI) « Intrants dépendance russe, biélorusse et ukrainienne » (Autres)</li>
        </ul>
        <h2 className='front-article__subtitle'>AAP « Métaux critiques »</h2>
        <p className='front-article__paragraph'>L'appel à projets « Métaux Critiques » a été lancé le 10 janvier 2022 afin de faire émerger des projets de production, de raffinage ou de recyclage des métaux dits critiques (lithium, nickel, cobalt, tantale, cuivre, etc.)</p>
        <h3 className='front-article__subsubtitle'>{`Exemples des laureats\nde l'AAP « Métaux critiques »`}</h3>
        <table className='front-article__table'>
        <tr>
            <th className='front-article__table-th'>Nom</th>
            <th className='front-article__table-td'>Imerys</th>
            <th className='front-article__table-td'>{`Eramet\n(Projet Relieve d’Eramet)`}</th>
            <th className='front-article__table-td'>{`Viridian\n(projet CoRaLi)`}</th>
            <th className='front-article__table-td'>Sanou Koura</th>
            <th className='front-article__table-td'>WeeeCycling</th>
        </tr>
        <tr>
            <th className='front-article__table-th'>Région</th>
            <td className='front-article__table-td'>Auvergne-Rhône-Alpes</td>
            <td className='front-article__table-td'>Hauts-de-France</td>
            <td className='front-article__table-td'>Alsace</td>
            <td className='front-article__table-td'>Grand Est</td>
            <td className='front-article__table-td'>Normandie</td>
        </tr>
        <tr>
            <th className='front-article__table-th'>Domain</th>
            <td className='front-article__table-td'>Extraction de lithium</td>
            <td className='front-article__table-td'>Recyclage de batteries</td>
            <td className='front-article__table-td'>Raffinage de lithium </td>
            <td className='front-article__table-td'>Valorisation des métaux</td>
            <td className='front-article__table-td'>Recyclage</td>
        </tr>
        <tr>
            <th className='front-article__table-th'>Decription</th>
            <td className='front-article__table-td'>La première exploitation minière de lithium en France</td>
            <td className='front-article__table-td'>Offre française de recyclage des batteries Li-ion à grande échelle</td>
            <td className='front-article__table-td'>Première raffinerie française de lithium</td>
            <td className='front-article__table-td'>Une usine capable de valoriser tous les métaux contenus dans les déchets électroniques</td>
            <td className='front-article__table-td'>Extraction des métaux critiques contenus dans les déchets électroniques</td>
        </tr>
    </table>
    <p className='front-article__source'>Source: Gouvernement de la France</p>
        <h2 className='front-article__subtitle'>Fonds « Métaux critiques »</h2>
        <div className='front-article__metals'>
        <div className='front-article__metals-title'>
            <p className='front-article__metals-title-number'>{`50-60\nMds €`}</p>
            <p className='front-article__metals-title-text'>Estimation de PDG d'Infravia sur les investissements nécessaires pour suivre la demande croissante de métaux critiques</p>
            <p className='front-article__source'>Source: Natixis</p>
        </div>
        <div className='front-article__metals-text'> 
        <p className='front-article__paragraph'>Un Fonds « Métaux critiques » <strong>doté de 505 M€</strong> encadré par la convention du Convention du 21 avril 2023 dédié à investir dans des projets sur l’ensemble 
            de la chaîne de valeur des minerais et métaux stratégiques, de l’extraction au recyclage, en vue de sécuriser les approvisionnements 
            de l’industrie française et européenne.</p>
        <p className='front-article__paragraph'>La firme privée <strong>Infravia gère un fond de 2 milliards d'euros, y compris 505 M € de l’Etat</strong>. D'après le PDG, <Link className='front-article__link' target="_blank" to="https://home.cib.natixis.com/articles/securing-critical-metal-supplies">il est estimé que <strong>50 à 60 milliards </strong> 
            d'euros d'investissements</Link> seront nécessaires pour faire face à la demande croissante de métaux critiques au cours des 10 prochaines années.</p>
        <p className='front-article__paragraph'>Le fond prendra, ainsi, <strong>des participations minoritaires dans les mines</strong>, ainsi que dans les infrastructures de raffinage, de transformation et 
            de recyclage liés <strong>au lithium, au nickel, au cobalt, au cuivre, à l'aluminium, au graphite et aux métaux des terres rares</strong> seront la principale préoccupation du fonds.</p>
        <p className='front-article__paragraph'>Les actifs sont recherchés dans des <strong>pays ayant des liens importants avec la France</strong>, comme le Canada, l'Australie et quelques nations d'Amérique du Sud, d'Asie du Sud-Est et d'Afrique.</p>
        </div>
        </div>
        </>
    )
  }