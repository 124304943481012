const deficitData = [
    {
      "name":"Demande de cuivre",
      "values":[
        {"date": 2022,"value":25.9286},
        {"date": 2025,"value":27.87296},
        {"date": 2030,"value":35.61305},
        {"date": 2035,"value":38.23158},
        {"date": 2040,"value":40.89577},
        {"date": 2045,"value":40.36074},
        {"date": 2050,"value":38.81762},
      ]
    },
    {
      "name": "Production de cuivre",
      "values":[
        {"date": 2022,"value":21.900},
        {"date": 2025,"value":22.7059},
        {"date": 2030,"value":24.521},
        {"date": 2035,"value":26.33778},
        {"date": 2040,"value":28.15456},
        {"date": 2045,"value":29.97133},
        {"date": 2050,"value":31.78811},
      ]
    }]

export default deficitData;