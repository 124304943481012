import { useEffect, useRef } from "react";
import { Helmet } from 'react-helmet-async';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import './Main.css';
import Intro from "../Intro/Intro";
import AboutProblem from "../AboutProblem/AboutProblem";
import AboutSolution from "../AboutSolution/AboutSolution";
import AboutProduct from "../AboutProduct/AboutProduct";
import AboutMetals from "../AboutMetals/AboutMetals";
import AboutInsights from "../AboutInsights/AboutInsights";
import AboutTeam from "../AboutTeam/AboutTeam";
import AboutRegulation from "../AboutRegulation/AboutRegulation";
import AboutWeSafeTheWorld from "../AboutWeSafeTheWorld/AboutWeSafeTheWorld";
import AboutQuestions from "../AboutQuestions/AboutQuestions";
import image from "../../images/logo_new.jpg";
import SafeBox from "../../images/safebox.webp";
import { organizationSchema } from "../../utils/constants/schema";

gsap.registerPlugin(ScrollTrigger);

export default function Main() {
  const mainRef = useRef(null);

//   useEffect(() => {
//     // Apply a scroll trigger to slow down the scroll speed
//     gsap.to(mainRef.current, {
//       scrollTrigger: {
//         trigger: mainRef.current,
//         start: "top top", // Start when the top of the page meets the top of the viewport
//         end: "bottom bottom", // End when the bottom of the content meets the bottom of the viewport
//         scrub: 10, // This value controls how much slower the scroll feels (2x slower in this case)
//       },
//     });

//     return () => {
//       ScrollTrigger.getAll().forEach(trigger => trigger.kill());
//     };
//   }, []);

  return (
    <>
      <Helmet>
        <title>WeSafe</title>
        <meta name="description" content="Unique plateforme européenne d'épargne en matériaux critiques. Achetez des métaux critiques de la tranisition numérique, énergétique sous forme physique." />
        <meta property="og:url" content={`https://wesafe.store/`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="WeSafe" />
        <meta property="og:description" content="Unique plateforme européenne d'épargne en matériaux critiques. Achetez des métaux critiques de la tranisition numérique, énergétique sous forme physique." />
        <meta property="og:image" content={image} />
        <link rel="canonical" href="https://wesafe.store/"></link>
        <link rel="preload" href={SafeBox} as="image"></link>
        <script type="application/ld+json">
          {JSON.stringify(organizationSchema)}
        </script>
      </Helmet>
      <main ref={mainRef}>
        <Intro image={SafeBox} />
        <AboutProblem />
        <AboutSolution />
        <AboutProduct />
        <AboutMetals />
        <AboutInsights />
        <AboutWeSafeTheWorld />
        <AboutTeam />
        <AboutRegulation />
        <AboutQuestions />
      </main>
    </>
  );
}
