import {React} from "react";
import { Link } from "react-router-dom";
import {Helmet} from 'react-helmet-async'
import './Ecology.css';
import umbrella from "../../images/ecology/umbrella.jpeg"
import treehand from "../../images/ecology/treehand.jpeg"
import leafmetal from "../../images/ecology/leafmetal.jpeg"
import recycle from "../../images/ecology/recycle.jpeg"
import chain from "../../images/ecology/chain.jpeg"
import image from "../../images/logo_new.jpg"

export default function Ecology () {
    return (
        <>
        <Helmet>
            <title>Mission WeSafe The World</title>
            <meta name="description" content="Ensemble, contribuons à l’amélioration de l’écosystème des Métaux Critiques et à la Souveraineté industrielle de la France et plus largement de l’Union Européenne." />
            <link rel="canonical" href="https://wesafe.store/wesafe-the-world" />

            <meta property="og:url" content={`https://wesafe.store/wesafe-the-world`} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Mission WeSafe The World" />
            <meta property="og:description" content="Contribuons à l’amélioration de l’écosystème des Métaux Critiques et à la Souveraineté industrielle de la France." />
            <meta property="og:image" content={image} />
            
            <meta name="twitter:card" content="summary" />
            {/* <meta name="twitter:site" content="@yourusername" /> */}
            <meta name="twitter:title" content="Mission WeSafe The World" />
            <meta name="twitter:description" content="Contribuons à l’amélioration de l’écosystème des Métaux Critiques et à la Souveraineté industrielle de la France" />
            <meta name="twitter:image" content={image} />
        </Helmet>
      <section className="ecology">
        <h1 className="ecology__title">Mission WeSafe The World</h1>
         <div className="ecology__intro">
        <p className="ecology__intro-text">Notre mission: <strong>améliorer notre secteur d’activité</strong></p>    
        <p className="ecology__intro-text">Ensemble, contribuons à l’amélioration de l’écosystème des Métaux Critiques et à <strong>la Souveraineté industrielle de la France</strong>  et plus largement de <strong>l’Union Européenne.</strong> </p>    
        <p className="ecology__intro-text">La mission de WeSafe est de <strong>participer à la transition numérique et écologique</strong>, mais aussi de contribuer à <strong>l’amélioration de la Supply Chain</strong> en général, ainsi que de participer au développement de <strong>l’économie circulaire.</strong></p>    
        </div> 
        <h2 className="ecology__subtitle">La création dans les Safes (nos entrepôts) de stocks de métaux critiques a deux aspects fondamentaux</h2>
        <div className="ecology__block">
        <img className="ecology__image-phone" src={umbrella} alt="stock"/>
        <div className="ecology__subblock">
            <div className="ecology__subblock-number">1</div>
                <p className="ecology__subblock-text">Permettre de <strong>créer un stock souverain et stratégique privé</strong> sur le sol européen pour subvenir aux besoins de nos industriels en cas de pénuries, 
                    problèmes géopolitiques et autres perturbations de marché (nos clients sont propriétaires de leurs actifs et libre de les revendre).</p>
            </div>
            <div className="ecology__image-container">
            <img className="ecology__image ecology__image_umbrella" src={umbrella} alt="ecology"/>
            </div>
        </div>
        <div  className="ecology__block">
        <div className="ecology__image-container">
        <img className="ecology__image-phone" src={recycle} alt="recycle"/>
        <img className="ecology__image ecology__image_recycle" src={recycle} alt="recycle"/>
        </div>
        <div className="ecology__subblock">
            <div className="ecology__subblock-number">2</div>
            <p className="ecology__subblock-text">Permettre par la même occasion à nos clients de contribuer à l’amélioration 
                    de <strong>la Supply Chain</strong> ainsi que de <strong>participer au développement de l’économie circulaire</strong> à travers notre programme, <strong>WeSafe The World.</strong></p>
            </div>
        </div>
        <div className="ecology__program">
        <h2 className="ecology__subtitle">Programme WeSafe The World</h2>
        <div className="ecology__program-block">
        <img className="ecology__program-image-phone" src={leafmetal} alt="metaux"/>
                <p className="ecology__program-text">Il est clair que parler d’activité bas carbone ou encore d’économie verte n’est pas adapté à notre métier.
                     En revanche, <strong>participer à la transition écologique et numérique</strong>, ainsi que contribuer <strong>à l’amélioration de notre secteur</strong> est un devoir.</p>
            <img className="ecology__program-image" src={leafmetal} alt="metaux"/>
            </div>
            <div className="ecology__program-block">
            <img className="ecology__program-image-phone" src={chain} alt="value-chain"/>
            <img className="ecology__program-image" src={chain} alt="value-chain"/>
                <p className="ecology__program-text">Le Programme WeSafe The World consiste donc à <strong>reverser une partie de nos bénéfices</strong> aux ONG, 
                    associations et acteurs du verdissement de la chaine de valeur minière au niveau mondial, ainsi qu’aux mêmes type d’acteurs de l’économie circulaire.</p>
            </div>
            <div className="ecology__program-block">
            <img className="ecology__program-image-phone" src={treehand} alt="horizon"/>
                <p className="ecology__program-text">Ce qui pour l’instant est <strong>un engagement contractuel de notre part</strong> (avec des reporting semestriels) deviendra à l’horizon hiver 2024-2025 
                    une structure juridique à part entière indépendante, dédiée à la dépollution progressive de notre secteur d’activité.</p>
            <img className="ecology__program-image" src={treehand} alt="horizon"/>
            </div>
            </div>
      </section>
      </>
    )
  }