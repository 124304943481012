import './InsightsCard.css';
import { Link } from "react-router-dom";
import Article from "../Article/Article"

export default function InsightsCard({insight, isIcons, article}) {
    return(
        <>
        {isIcons === 0 ? (
        <Link to={`./${article?.year.replace(/\./g, '-').toLowerCase()}/${article?.name.replace(/\s+/g, '-').toLowerCase()}`} style={{ textDecoration: 'none' }} element={Article}>
        <section className='insights-card'>
            <img className='insights-card__image'
             src={article?.image}
             alt={article?.name}
             />
            <div className='insights-card__container'>
            <h3 className='insights-card__title'>{article?.name}</h3>
            <p className='insights-card__date'>{article?.year} #{article?.type}</p>
            <p className='insights-card__description'>{article?.brief}</p>
            {/* https://www.w3schools.com/jsref/jsref_regexp_i.asp */}
            {/* <Link to={`./${insight.title}`}> */}
            <div className='insights-card__link'>Lire la suite</div>
            <div className='insights-card__square'></div>
            </div>
        </section>
        </Link>) : (
        <Link to={`./${article?.year.replace(/\./g, '-').toLowerCase()}/${article?.name.replace(/\s+/g, '-').toLowerCase()}`} style={{ textDecoration: 'none' }} element={Article}>     
            <section className='insights-list'>
            <img className='insights-list__image'
             src={article?.image}
             alt={article?.name}
             />
            <div className='insights-list__container'>
            <div className='insights-list__first-line'>
            <h3 className='insights-list__title'>{article?.name}</h3>
            <p className='insights-list__date'>{article?.year} #{article?.type}</p>
            </div>
            {/* <p className='insights-list__date-mobile'>{insight.date} #{insight.type}</p> */}
            <p className='insights-list__link'>Lire la suite</p>
            {/* <p className='insights-list__description'>{insight.brief}</p> */}
            <p className='insights-list__description'>{article?.brief}</p>
            {/* https://www.w3schools.com/jsref/jsref_regexp_i.asp */}
            {/* <Link to={`./${insight.title}`}> */}
            <div className='insights-list__square'></div>
            </div>
        </section>
        </Link>
        )}
        </>
    )
}