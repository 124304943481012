const consumption = {
    "children": [
      {"name": "Chine", "value": 11.894480, "objectives": "<b>2937727</b>:<br>12%<br>"},
      {"name": "Autre Europe", "value": 0.946917, "objectives": "<b>2937727</b>:<br>12%<br>"},
      {"name": "Inde", "value": 0.937801, "objectives": "<b>2937727</b>:<br>12%<br>"},
      {"name": "ASEAN", "value": 1.121931, "objectives": "<b>2937727</b>:<br>12%<br>"},
      {"name": "Japon", "value": 0.929938, "objectives": "<b>2937727</b>:<br>12%<br>"},
      {"name": "Corée", "value": 0.712986, "objectives": "<b>2937727</b>:<br>12%<br>"},
      {"name": "Autre Asie & Australasie", "value": 1.296961, "objectives": "<b>2937727</b>:<br>12%<br>"},
      {"name": "Amérique du Nord", "value": 1.995279, "objectives": "<b>2937727</b>:<br>12%<br>"},
      {"name": "Amérique du Sud", "value": 1.061802, "objectives": "<b>2937727</b>:<br>12%<br>"},
      {"name": "Afrique", "value": 0.391124, "objectives": "<b>2937727</b>:<br>12%<br>"},
      {"name": "UE27 + Royaume - Uni", "value": 0.2937727, "objectives": "<b>2937727</b>:<br>12%<br>"},
    ],
    "name": "Monde"
  };
  
  export default consumption;

  