import copperA from '../images/offer_copper.jpg'
import copperB from '../images/offer_copper.jpg'
import copperOffer from '../images/aboutmetals/copper.jpg'
import cobaltOffer from '../images/aboutmetals/cobalt.jpg'
import nickelOffer from '../images/aboutmetals/nickel.jpg'
import jerome from '../images/aboutteam/photo_jerome.jpg'
import christophe from '../images/aboutteam/photo_christophe.png'
import artem from '../images/aboutteam/photo_artem.jpg'
import france from '../images/articles/france.jpg'
import copperdeficit from '../images/articles/copperdeficit/copper_deficit.png'
import France2030 from '../components/FrontendArticles/France2030/France2030'
import CopperDeficit from '../components/FrontendArticles/CopperDeficit/CopperDeficit'

const frontendInsights = [{name:'France 2030 et métaux critiques', year: '14.04.2024', type: 'analyse', image: france, brief: 'Quelle est la stratégie de la France pour sécuriser son accès aux métaux critiques?', 
    description: <France2030/>},
    {name:'Déficit de Cuivre', year: '21.08.2024', type: 'analyse', image: copperdeficit, brief: 'Pourquoi un déficit et une augmentation des prix sont attendus ?', 
        description: <CopperDeficit/>},
]

export default frontendInsights

export const products = [
    {title:'Cuivre', src: copperOffer, link: './copper', alt: 'copper'},
    {title:'Nickel\n(automne 2024)', src: nickelOffer, link: './nickel', alt: 'nickel'},
    {title:'Cobalt\n(automne 2024)', src: cobaltOffer, link: './cobalt' ,alt: 'cobalt'},
]

export const team =[
    {name: 'Jérôme Thomasse' , src:jerome, profession: 'CEO', description:'Entrepreneur, Spécialiste de la Gestion de Patrimoine et de l’Epargne depuis 25 ans'},
    {name: 'Christophe Roy' , src:christophe, profession: 'CMO', description:'Spécialiste Marketing, Gestionnaire de projet et entrepreneur depuis 25 ans'},
    {name: 'Artem Zubov' , src:artem, profession: 'CTO', description:'Développeur Web,\n Analyste Macro & Commodities'},
]